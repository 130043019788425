import React from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import BTable from 'react-bootstrap/Table';
import { 
  useTable, 
  useSortBy, 
  useRowSelect,
  usePagination, 
  useFilters, useGlobalFilter, useAsyncDebounce, // @see: https://react-table.tanstack.com/docs/examples/filtering
  useExpanded, // @see: https://react-table.tanstack.com/docs/examples/expanding
} from 'react-table'

// @see: https://react-table.tanstack.com/docs/examples/row-selection-and-pagination
import IndeterminateCheckbox from './Select'

// @see: https://react-table.tanstack.com/docs/examples/filtering
import { 
  GlobalFilter, DefaultColumnFilter, SelectColumnFilter, SliderColumnFilter, NumberRangeColumnFilter, 
  // fuzzyTextFilterFn, filterGreaterThan 
} from './Filter'

import { isEmpty as _isEmpty } from "lodash"
import moment from 'moment'



const PAGE_SIZE = [10, 20, 30, 40, 50]; // 50 is max allowed by JSON:API



function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val






// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
const Table = (props) => {



  // @see: https://react-table.tanstack.com/docs/examples/filtering
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )
  // @see: https://react-table.tanstack.com/docs/examples/filtering
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      // Filter: DefaultColumnFilter,
      Filter: <React.Fragment></React.Fragment>,
    }),
    []
  )



  const {
      columns,
      data,
      fetchData,
      loading,
      pageCount: controlledPageCount,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // canPreviousPage: _canPreviousPage,
    // canNextPage: _canNextPage,
    canPreviousPage,
    canNextPage,

    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    // Get the state from the instance
    state: { 
      pageIndex, pageSize, selectedRowIds,
      
      globalFilter, // @see: https://react-table.tanstack.com/docs/examples/filtering
      filters, // @see: https://react-table.tanstack.com/docs/examples/filtering

      expanded,
    },

    // @see: https://react-table.tanstack.com/docs/examples/filtering
    rows, 
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,

    allColumns,
    getToggleHideAllColumnsProps,

    //... other props from useTable
    toggleAllRowsExpanded,
    isAllRowsExpanded,

  } = useTable(
    {
      columns,
      data,
      initialState: { 
        hiddenColumns: columns.filter(col => col.show === false).map(col => col.accessor), // @see: https://github.com/tannerlinsley/react-table/discussions/1971
        pageIndex: 0, 
        pageSize: // s4n
          // PAGE_SIZE[0],
          // PAGE_SIZE[PAGE_SIZE.length - 1]
          parseInt(process.env.SOFT4NET_JSONAPI_PAGE_LIMIT_PAGE_SIZE)
        ,
        filters: [ // set initial filter to show events from the begining of a day
          // {
          //   id: `created`,
          //   value: {
          //     operator: `>=`,
          //     value: moment().startOf('day').unix(),
          //   }
          // },

          {
            id: `created`,
            value: [
              {
                  path: `created`,
                  operator: '>=',
                  value: moment().startOf('day').unix(),
              },
              {
                  path: `created`,
                  operator: '<=',
                  value: moment().endOf('day').unix(),
              }
            ]
          },

        ],
        
        // expanded: true,

      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,

      // @see: https://react-table.tanstack.com/docs/examples/filtering
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      manualFilters: true, // https://react-table.tanstack.com/docs/api/useFilters#usefilters

      // We also need to pass this so the page doesn't change when we edit the data.
      // autoResetPage: !skipReset,
      autoResetSelectedRows: false, // @see: https://react-table.tanstack.com/docs/api/useRowSelect#userowselect
      // disableMultiSort: true,
    },

    // @see: https://react-table.tanstack.com/docs/examples/filtering
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!

    // useSortBy, // @see: https://react-table.tanstack.com/docs/examples/sorting
    
    useExpanded, // Use the useExpanded plugin hook

    usePagination,

    // @see: https://react-table.tanstack.com/docs/examples/row-selection-and-pagination
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }

  )

  

// run an effect that expands all the rows and keeps them that way. 
// React.useMemo(() => toggleAllRowsExpanded(true), [toggleAllRowsExpanded]);
// React.useEffect(() => {
//   toggleAllRowsExpanded(expandAllSubComponents) // NOTE expandAllSubComponents is a boolean I use to toggle open/closed
// }, [expandAllSubComponents, toggleAllRowsExpanded]) 



// console.log(props.pagesCollection.current?.[pageSize]?.[pageIndex])
//   const canPreviousPage = props.pagesCollection.current?.[pageSize]?.[pageIndex]?.hasPrevPage || _canPreviousPage;
//   const canNextPage = props.pagesCollection.current?.[pageSize]?.[pageIndex]?.hasNextPage || _canNextPage;



  // const _selectedRowIds = React.useMemo(() => selectedRowIds, [selectedRowIds]) // s4n
  // Pass selected rows to parent component
  React.useEffect(() => {
    props.setSelectedFlatRows(selectedFlatRows);
  }, [
    selectedFlatRows,
  ])


// console.log(`expanded: `, expanded)
// console.log(`isAllRowsExpanded: `, isAllRowsExpanded)
  React.useEffect(() => {
    props.setIsAllRowsExpanded(isAllRowsExpanded);
  }, [
    isAllRowsExpanded,
  ])

  // React.useEffect(() => {
  //   if (filters.length !== 0) {
  //     setPageSize(PAGE_SIZE[PAGE_SIZE.length - 1])
  //   }
  // }, [
  //   filters,
  // ])

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
// console.log(`
//   pageIndex: ${pageIndex}
//   pageSize: ${pageSize}
// `)
// console.log(filters)
    const _pageSize = filters.length === 0 ? 
      pageSize 
      : 
      // PAGE_SIZE[PAGE_SIZE.length - 1]
      parseInt(process.env.SOFT4NET_JSONAPI_PAGE_LIMIT_PAGE_SIZE)

// console.log(_pageSize)

    // if (_isEmpty(selectedRowIds)) { // if we have rows selected we prevent data fetching because it will reset selection when sync fetch is fired
      fetchData({ 
        pageIndex, 
        
        // pageSize, 
        pageSize: _pageSize, // s4n

        filters,
      })
    // }

  }, [
    fetchData, // is it necessary to depent on it?
    pageIndex, 
    pageSize,
    filters,
  ])

  // Render the UI for your table
  return (
    <>

      {/* Manual selections for showing and hiding columns */}
      {/* <div>
        <div>
          <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle
          All
        </div>
        {allColumns.map(column => (
          <div key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
              {column.id}
            </label>
          </div>
        ))}
        <br />
      </div> */}

      <BTable 
        striped 
        bordered 
        hover 
        responsive
        // size="sm" 
        {...getTableProps()}
    >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th 

                  {...column.getHeaderProps()}
                  // {...column.getHeaderProps(column.getSortByToggleProps())} 

                >
                  {/* Render the columns filter UI */}
                  <div className="mb-2">{column.canFilter ? column.render('Filter') : null}</div>

                  {column.render('Header')}
                  
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>

                </th>
              ))}
            </tr>
          ))}

          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>

        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length === 0 ? 
            <React.Fragment>
              <tr>
                <td colSpan={visibleColumns.length} className="py-5 text-center">Brak wyników</td>
              </tr>
            </React.Fragment>
            :
            <React.Fragment>
              {page.map((row, i) => {            
    // console.log(row.original)
                prepareRow(row)

                return (
                  <tr 
                    {...row.getRowProps()}

                    className={row.original.className} // s4n
                    {...row.original.tooltip} // s4n
                    // {...row.original.popover} // s4n
                    // style={row.original?.styleColor ? {color: `#fff`, backgroundColor: row.original.styleColor} : {}} // s4n

                    // onClick={() => console.log(row.original)}

                  >
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
              <tr>
                {loading ? (
                  // Use our custom loading state to show a loading indicator
                  <td colSpan="10000">Ładowanie...</td>
                ) : (
                  <td colSpan="10000">
                    {page.length} {page.length === 1 ? `rekord` : `rekordy`} | {props.s4nFooter}
                    {/* of ~{controlledPageCount * pageSize}{' '} results */}
                  </td>
                )}
              </tr>
            </React.Fragment>
          }
        </tbody>
      </BTable>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}

      <div className="pagination">
        {_isEmpty(selectedRowIds) &&
            <React.Fragment>
                <button className="btn btn-primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                <button className="btn btn-primary" onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>
                <button className="btn btn-primary" onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>
                {/* <button className="btn btn-primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button> */}
            </React.Fragment>
        }
        
        <span>
          Strona{' '}
          <strong>
            {pageIndex + 1} 
            {/* of {pageOptions.length} */}
          </strong>{' '}
        </span>

        {/* <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span> */}

        {/* <span>
          | Elementów na stronę{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {PAGE_SIZE.map(pageSize => (
              <option key={pageSize} value={pageSize}>{pageSize}</option>
            ))}
          </select>
        </span> */}

      </div>


      {props.debug &&
        <div>
          {/* {props.s4nFooter} */}

          <pre>
            <code>
              {JSON.stringify(
                  {
                      pageIndex,
                      pageSize,
                      pageCount,
                      canNextPage,
                      canPreviousPage,
                  },
                  null,
                  2
              )}
            </code>
          </pre>

          <pre>
            <code>
              {JSON.stringify(
                {
                  selectedRowIds: selectedRowIds,
                  'selectedFlatRows[].original': selectedFlatRows.map(
                    d => d.original
                  ),
                },
                null,
                2
              )}
            </code>
          </pre>

          <pre>
            <code>{JSON.stringify(filters, null, 2)}</code>
          </pre>
        </div>
      }



    </>
  )
}

export default Table;