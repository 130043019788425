// @see: https://stackoverflow.com/questions/40885923/countdown-timer-in-react
import React, { useState, useEffect, Fragment } from 'react';

const Component = (props) => {
    const [timeleft, setTimeleft] = useState(props.timeleft);

    useEffect(() => {
// console.log(`<CountDown /> rerendered!`)

        const interval = setInterval(function() {
            // if (timeleft <= 0) {
            //   clearInterval(interval);
            // }

            setTimeleft(timeleft => timeleft -= 1);
          }, 1000);

        // return () => clearInterval(interval); // the same as componentWillUnmount() ?
        return () => {
            setTimeleft(props.timeleft); 
            clearInterval(interval)
        }; // the same as componentWillUnmount() ?
    }, 
        [props.componentRerenderCounter] // the same as componentDidMount()
    );

    return (
        <Fragment>
            <span style={{color: `black`}}>
                Odświezenie za: {timeleft}s
                {/* , cykli: {props.componentRerenderCounter} */}
            </span>
        </Fragment>
    )
}

export default Component