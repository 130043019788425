import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// import { navigate } from 'gatsby';
import { navigate } from "gatsby-plugin-intl"
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

import { globalHistory } from "@reach/router"

import Login from './../Login'



import { connect } from 'react-redux'
import { userLoginIsAllowed, userLogout } from './../../../s4n/Shop/ext/redux/actions/user'
import { selectUserLoginIsAllowed } from './../../../s4n/Shop/ext/redux/selectors/user'
import GeneralUtils from './../../../../utils/common'



const PrivateRoute = (props) => {
  const { 
    component: Component, 
    location, // from @reach/router ???
    userAuthenticated, // injected by withDrupalOauthConsumer(PrivateRoute);

    disableCheckAccess,

    ...rest 
  } = props;



  const [loading, setLoading] = useState(true);
  const componentDidMount = async () => {
// console.log(`userAuthenticated: `, userAuthenticated)
    if (userAuthenticated) {
      const authorization = props.drupalOauthClient.isLoggedIn();
      const isLoginAllowed = disableCheckAccess || await GeneralUtils.isLoginAllowed(authorization);
// console.log(`isLoginAllowed: `, isLoginAllowed)
      if (isLoginAllowed) {
        await props.setLoginIsAllowed();
      } else {
        // await props.onUserLogout();
        await GeneralUtils.logout(props);
        navigate(`/user/access-denied`);
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    componentDidMount();
    // return () => true; // the same as componentWillUnmount() ?      
  }, [userAuthenticated]);

  if (loading) {
    return null;
  }

  // if (loading) {
  //   return null;
  // } else if (!userAuthenticated && !isUserVerified) {
  //   navigate(`/user/access-denied`);
  //   return null;
  // }






  // if (!userAuthenticated) {
  //   // If we’re not logged in, redirect to the home page.
  //   // navigate(`/user/login`)
  //   navigate(`/user/login-register`)
  //   return null;
  // }

  if (
    !userAuthenticated && 
    // location.pathname !== `/user/login-register`
    globalHistory.location.pathname !== `/user/login`
  ) {
    // navigate(`/user/login-register`)
    // return null;
    return <Login />
  }

  if (props.userLoginIsAllowed) {
    return <Component {...rest} />
  }

  return null;

  // return <Component {...rest} />
  // return <React.Fragment>
  //   <p>selectUserIsAdmin: {props.selectUserIsAdmin() ? `YES` : `NO`}</p>
  //   <Component {...rest} />
  // </React.Fragment>
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

// export default withDrupalOauthConsumer(PrivateRoute);
const mapStateToProps = state => {
  return {
    // selectUserIsAdmin: () => selectUserIsAdmin(state),
    userLoginIsAllowed: selectUserLoginIsAllowed(state),
  }
}
const mapDispatchToProps = dispatch => ({
    onUserLogout: () => dispatch(userLogout()),
    setLoginIsAllowed: () => dispatch(userLoginIsAllowed()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthConsumer(PrivateRoute));