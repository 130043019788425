import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"

// s4n
import _ from 'lodash'
import { cloneDeep as _cloneDeep } from "lodash"
import { useIntl, Link } from 'gatsby-plugin-intl'
import { jsonapiClient } from 's4n-jsonapi-client' // for events we do not want to user redux, we want to call an API instantly
import {
    eventAdd,
    // eventFetch, // we do not need redux state management
    eventPatch, 
    eventRemove,
    todayFetch,
    yesterdayHasCloseEventFetch,
    notificationFetch,
    getCashRegisterAmount,
    productFetch,
} from './../../Shop/ext/redux/actions/event';
import { 
    cartFetch 
} from './../../Shop/ext/redux/actions/cart';
import { 
    getCheckout,
    patchCheckout,
    postCheckout,
} from './../../Shop/ext/redux/actions/checkout';
import { selectUserIsAdmin } from './../../Shop/ext/redux/selectors/user' 
import { 
    selectEventTypeByUuid, 
    selectTodayEvents, 
    selectLastOpenDayEvent,
    selectYesterdayEventsHasCloseEvent, 
    selectTodayEventsHasCloseEvent,
    selectGetCashRegisterAmount,
} from './../../Shop/ext/redux/selectors/event' 

// @see: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/pagination-controlled?from-embed=&file=/src/App.js:155-597
import Table from './Table'
// import makeData from './makeData'
// Let's simulate a large dataset on the server (outside of our component)
// const serverData = makeData(100)

// import columns from './Table/columns'
import { 
    GlobalFilter, DefaultColumnFilter, SelectColumnFilter, SliderColumnFilter, NumberRangeColumnFilter, 
    fuzzyTextFilterFn, filterGreaterThan,
    DateColumnFilter,
    DateRangeColumnFilter,
    NumberRangeColumnFilterJsonApi,
} from './Table/Filter'

import CommerceHelper from './../../Shop/s4n/utils/commerce-helper'
import EventAttributes from './../../../forms/EventAttributes'
import { DisplayFormikState } from "./../../../forms/formikHelper"

import EventEntity from './Entity'
import EventRepository, { PAYMENT_METHODS, PAYMENT_METHOD_2_ICON } from './Repository'
import GeneralUtils from './../../../../utils/common'
import CountDown from './../../CountDown'
import { Modal, Button } from 'react-bootstrap'

import EventDetails from './Details';
// import OrderItems from './../../AccountManagement/Commerce/Orders/Details/OrderItems'


const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;



    const [eventDetails, setEventDetails] = useState(null);
    const handleEventDetails = async (eventUuid) => {
        setEventDetails(eventUuid);
    }

    const [eventEdit, setEventEdit] = useState(null);
    const handleEventEdit = async (eventUuid) => {
        const authorization = props.drupalOauthClient.isLoggedIn();
        const payload = await EventEntity.getByUuid(eventUuid, authorization, intl);
        const { data, included } = payload;
        const mappedIncludes = getMappedIncludes(included);
        const relationshipFieldOrder = EventEntity.getOrder(data, { mappedIncludes }); // commerce_order--default

        const populate = await EventEntity.formPopulate(
            { event: data, included }, 
            { mappedIncludes, authorization, intl }
        )

        setEventEdit({
            event: data,
            included,
            order: relationshipFieldOrder,
            mappedIncludes,
            populate,
        });
    };

    const [eventRemove, setEventRemove] = useState(null);
    const handleEventRemove = async (eventUuid) => {
        const authorization = props.drupalOauthClient.isLoggedIn();
        const payload = await EventEntity.getByUuid(eventUuid, authorization, intl);
        const { data, included } = payload;
        const mappedIncludes = getMappedIncludes(included);
        const relationshipFieldOrder = EventEntity.getOrder(data, { mappedIncludes }); // commerce_order--default

        setEventRemove({
            event: data,
            order: relationshipFieldOrder,
        });
    }



    if (!props?.event?.event_types) {
        return null;
    }



    const { cart: cartReducer } = props;
    useEffect(() => {
        // if (cartReducer.carts.length) {
        if (!CommerceHelper.isCartEmpty(props)) {
            props.getCheckout(cartReducer.carts[0]);
        }
    }, [cartReducer.carts])



    /**
     * accessrs values are used for filtering as filter key!!!
     * @see: https://react-table.tanstack.com/docs/api/useTable#column-options
     */
    const columns = React.useMemo(
        () => [

            // @see: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/expanding?file=/src/App.js:1860-2791
            {
                // Build our expander column
                id: 'expander', // Make sure it has an ID
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                    <span {...getToggleAllRowsExpandedProps()}>
                        {isAllRowsExpanded ? <i class="fas fa-arrow-down"></i> : <i class="fas fa-arrow-right"></i>}
                    </span>
                ),
                Cell: ({ row }) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    row.canExpand ? (
                        <span
                            {...row.getToggleRowExpandedProps({
                                style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 2}rem`,
                                },
                            })}
                            >
                            {row.isExpanded ? <i class="fas fa-arrow-down"></i> : <i class="fas fa-arrow-right"></i>}
                        </span>
                    ) 
                    : 
                    // null,
                    (row.depth ? <i class="fas fa-level-up-alt fa-rotate-90"></i> : null) // @see: https://github.com/tannerlinsley/react-table/issues/1892
            },

            {
                Header: t({ id: `soft4net_node_event_id` }),
                accessor: `id`,
            },
            {
                id: `field_event_type.id`, // Required if accessor is a function
                Header: t({ id: `soft4net_node_event_type` }),
                accessor: row => row[`field_event_type.id`], // relation
                Filter: SelectColumnFilter,
                filter: 'includes',
                Cell: (propsCell) => { // Column custom renderer
                    const { value: taxonomyTermEventTypeId } = propsCell;
                    const relationshipFieldEventType = props.selectEventTypeByUuid(taxonomyTermEventTypeId);

                    return (
                        <React.Fragment>
                            <span 
                                style={{ backgroundColor: relationshipFieldEventType?.attributes.field_color }}
                                className={`badge event-type mr-1 ${_.kebabCase(relationshipFieldEventType?.attributes.name)}`}
                            >
                                {/* {t({ id: `soft4net_shop_commerce_account_management_commerce_orders_status_${relationshipFieldEventType.attributes.name}` })} */}
                                {relationshipFieldEventType?.attributes.name}
                            </span>
                        </React.Fragment>
                    )
                },
                // getProps: () => ({ someFunc: () => alert("clicked")}) // ???
            },
            {
                id: `created` ,
                Header: t({ id: `soft4net_node_event_date` }),
                // accessor: `created`,
                accessor: row => row[`created`],
                // Filter: DateColumnFilter,
                Filter: DateRangeColumnFilter,
                filter: 'text', // @see: https://react-table.tanstack.com/docs/api/useFilters#column-options
                Cell: (propsCell) => { // Column custom renderer
                    const { value: created } = propsCell;

                    return (
                        <React.Fragment>
                            {GeneralUtils.dateFormat(created, `DD.MM.YYYY HH:mm`, intl.locale)}
                        </React.Fragment>
                    )
                },
            },
/*
            {
                id: `uid.name`, // Required if accessor is a function
                Header: t({ id: `soft4net_node_event_createdBy` }),
                accessor: row => row[`uid.name`], // relation 
                // Filter: SelectColumnFilter,
                // filter: 'includes',
                Filter: DefaultColumnFilter,
                filter: 'text', // @see: https://react-table.tanstack.com/docs/api/useFilters#column-options
                // filter: 'includes',
                // filter: 'fuzzyText', // Use our custom `fuzzyText` filter on this column
                show: false,
            },
*/
            {
                Header: t({ id: `soft4net_node_event_createdBy` }),
                accessor: `user_name`,
                show: false,

                // Filter: DefaultColumnFilter,
                // // Use our custom `fuzzyText` filter on this column
                // filter: 'text',
            },
            {
                id: `field_booking_info.value`,
                Header: t({ id: `soft4net_node_event_bookingInfo` }),
                accessor: row => row[`field_booking_info.value`],
                Filter: DateRangeColumnFilter,
                filter: 'text', // @see: https://react-table.tanstack.com/docs/api/useFilters#column-options
            },
            {
                // id: `field_order.order_total.total.number`,
                id: `field_order.total_price.number`,
                Header: t({ id: `soft4net_node_event_orderTotal` }),
                accessor: row => row[
                    // `field_order.order_total.total.number`
                    `field_order.total_price.number`
                ], // relation 
                
                Filter: NumberRangeColumnFilterJsonApi,
                filter: 'text',
                // filter: 'between', // DO NOT PASS FILTER, WHY ??? !!!!
                
                Cell: (propsCell) => { // Column custom renderer
                    const { value: order_total_number } = propsCell;

                    return EventEntity.getOrderTotalFormatted(order_total_number);
                },
            },
            {
                id: `field_payment_method`,
                accessor: row => row[`field_payment_method`],
                Header: t({ id: `soft4net_node_event_paymentGateway` }),
                Filter: SelectColumnFilter,
                // filter: 'includes',
                filter: 'text',
            },
            {
                id: `field_sales_document`,
                accessor: row => row[`field_sales_document`],
                Header: t({ id: `soft4net_node_event_salesDocument` }),
                Filter: SelectColumnFilter,
                // filter: 'includes',
                filter: 'text',
            },
            {
                id: `field_order.order_total.adjustments.0.percentage`,
                Header: t({ id: `soft4net_node_event_taxRate` }),
                accessor: row => row[`field_order.order_total.adjustments.0.percentage`], // relation 
                // Filter: SelectColumnFilter,
                // filter: 'text',
                // filter: 'includes',
                Cell: (propsCell) => { // Column custom renderer
                    const { value: percentage } = propsCell;

                    return percentage ? `${percentage * 100}%` : `-`;
                },
            },
            // {
            //     id: `field_order.order_items.quantity`,
            //     Header: t({ id: `soft4net_node_event_order_items_quantity` }),
            //     accessor: row => row[`field_order.order_items.quantity`], // relation 
            //     Cell: (propsCell) => { // Column custom renderer
            //         const { value: quantity } = propsCell;

            //         return quantity || `-`;
            //     },
            // },
            // {
            //     id: `body.value`,
            //     Header: t({ id: `soft4net_node_event_description` }),
            //     accessor: row => row[`body.value`], // relation 
            //     Filter: DefaultColumnFilter,
            //     filter: 'text', // @see: https://react-table.tanstack.com/docs/api/useFilters#column-options
            //     // filter: 'fuzzyText', // Use our custom `fuzzyText` filter on this column
            // },
            {
                id: `field_order.order_items.purchased_entity.id`,
                Header: `Zamówione produkty`,
                accessor: row => row[`field_order.order_items.purchased_entity.id`], // relation 
                Filter: SelectColumnFilter,
                filter: 'includes',
                // Cell: (propsCell) => { // Column custom renderer
                //     const { row } = propsCell;
                //     return (
                //         <React.Fragment>
                //             {/* <span>Zamówione produkty</span> */}
                //             {/* ASYNC DATA FROM ANOTHER API CALL - NOT PREFERABLE */}
                //             {/* <EventDetails eventUuid={row.original.uuid} showOnlyOrderDetails={true} tableCellDisplay={true} /> */}
                //             {/* <OrderItems orderItems={relationshipFieldOrderRelationshipOrderItems} mappedIncludes={mappedIncludes} tableCellDisplay={true} /> */}
                //             {/* STATIC DATA FROM table fetchData() - PREFERABLE */}
                //             <OrderDetails order={row.original.raw.field_order} mappedIncludes={row.original.raw.mappedIncludes} tableCellDisplay={true} />
                //         </React.Fragment>
                //     )
                // },
            },
            {
                id: `actions`,
                Header: t({ id: `soft4net_node_event_actions` }),
                accessor: row => row[`actions`],
            },
        ],
        [
            props?.event?.event_types
        ]
    )

    // We'll start our table without any data
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false) // const [loadingModalData, setLoadingModalData] = useState(false)
    const [loadingTableData, setLoadingTableData] = useState(false)
    
    /**
     * because of component refresh we cannot use state we must keep pageCount value betweeen rerenders of a component!!!
     */
    const pageCount = useRef(0); // const [pageCount, setPageCount] = useState(0);
    const pagesCollection = useRef({});
    

    const fetchIdRef = useRef(0) // this won't recreate on every rerender, it will keep it's value among rerenders ??? !!!
    const [selectedFlatRows, setSelectedFlatRows] = useState([]) // we get selected rows from Table component
    const [isAllRowsExpanded, setIsAllRowsExpanded] = useState(false) // we get selected rows from Table component
    
    const invokeFetchData = () => fetchIdRef.current = ++fetchIdRef.current;

    /**
     * pageSize, pageIndex - these params we get from within Table component instance, i belive this can be set on Table component as params!!!
     */
    const fetchData = useCallback(async (params) => {

        const { pageSize, pageIndex, filters } = params;

// console.log(`fetchData`)
        // This will get called when the table needs new data
        // You could fetch your data from literally anywhere,
        // even a server. But for this example, we'll just fake it.

        // Give this fetch an ID
        // const fetchId = ++fetchIdRef.current

        // Set the loadingTableData state
        setLoadingTableData(true)
/*
        // We'll even set a delay to simulate a server here
        setTimeout(() => {
            // Only update the data if this is the latest fetch
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex
                const endRow = startRow + pageSize
                setData(serverData.slice(startRow, endRow))

                // Your server could send back total page count.
                // For now we'll just fake it, too
                setPageCount(Math.ceil(serverData.length / pageSize))

                setLoadingTableData(false)
            }
        }, 1000)
*/
        // Only update the data if this is the latest fetch
        // if (fetchId === fetchIdRef.current) {
// const startRow = pageSize * pageIndex
// const endRow = startRow + pageSize

            const { serverData, hasNextPage, hasPrevPage } = await EventRepository.getServerData(
                pageSize, pageIndex, filters, 
                { // context object
                    intl,
                    authorization: props.drupalOauthClient.isLoggedIn(), 
                    EventActions: ({ uuid, isModified, userName, userColor, nid, hasDescription }) => {
                        userColor = userColor || `#593bdb`;

                        const extraPropsUserButton = {
                            style: {borderColor: userColor, backgroundColor: userColor},
                            title: `Użytkownik: ${userName}`,
                        }

                        const extraPropsEventDetailsButton = hasDescription ? {
                            style: {borderColor: `red`, backgroundColor: `red`, color: `#fff`},
                        } : {}

                        return (
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-sm btn-primary" {...extraPropsUserButton}>
                                    <i className="ti-user" aria-hidden="true"></i>
                                </button>
                                {/* <button type="button" className="btn btn-sm btn-outline-primary"><i className="ti-pencil" aria-hidden="true"></i></button> */}
                                {/* <!-- Button trigger modal --> */}
                                <button onClick={() => handleEventDetails(uuid)} type="button" className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target={`#event-details`} {...extraPropsEventDetailsButton}>
                                    <i className="ti-align-justify" aria-hidden="true"></i>
                                </button>
        
                                <button onClick={() => handleEventEdit(uuid)} type="button" className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target={`#event-edit`}>
                                    <i className="ti-pencil" aria-hidden="true"></i>
                                </button>
        
                                <React.Fragment>
                                    <button onClick={() => handleEventRemove(uuid)} type="button" className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target={`#event-delete`}>
                                        <i className="ti-close" aria-hidden="true"></i>
                                    </button>
                                    {props.selectUserIsAdmin && isModified &&
                                        <button type="button" className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target={`#event-delete`} title="Pokaż wersje">
                                            <a href={`${process.env.SOFT4NET_SOURCE_BASE_URL}/user?destination=/pl/node/${nid}/revisions`} target="_blank">
                                                <i class="fas fa-code-branch"></i>
                                            </a>
                                        </button>
                                    }
                                </React.Fragment>
        
                                {/* {isModified &&
                                    <button 
                                        type="button" className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target={`#event-modify-info-${uuid}`}
                                        // {...tooltip}
                                        // {...popover}
                                    >
                                        <i className="ti-close" aria-hidden="true"></i> Modified
                                    </button>
                                } */}
                            </div>
                        )
                    },
                }
            ); // s4n

            // setData(serverData.slice(startRow, endRow)) // here we fetch all events and we paginate afterwards on whole collection, NOT PERFORMANT!!!
            setData(serverData); // @todo: implement pagnation via JSONAPI more in description of method getServerData
            // Your server could send back total page count.
            // For now we'll just fake it, too



            /**
             * JSON:API does not return all available pages for collection therefore we must build it dynamically!!!
             * @see: https://www.drupal.org/docs/core-modules-and-themes/core-modules/jsonapi-module/pagination
             * because of component refresh we cannot React.useState() we must keep pageCount value betweeen rerenders of a component that's why we use React.useRef()!!!
             * 
             * @todo:  When changing pageSize something goes wrong - DEBUG!!!
             */
            // setPageCount(Math.ceil(serverData.length / pageSize)) // from React Table

            if (typeof pagesCollection.current[pageSize] === `undefined`) {
                pagesCollection.current[pageSize] = {}
                pageCount.current = 0;
            }
            pagesCollection.current[pageSize][pageIndex] = {
                visited: pagesCollection.current[pageSize][pageIndex]?.visited || false,
                hasNextPage,
                hasPrevPage,
            }
// console.log(`pagesCollection: `, pagesCollection.current)
            pageCount.current = 
                !pagesCollection.current[pageSize][pageIndex]?.visited ? 
                    pagesCollection.current[pageSize][pageIndex]?.hasNextPage ? 
                        (pageCount.current === 0 ? 2 : pageCount.current + 1) // if initial page (all pages count === 0) so we must add 2 otherwise 1
                        : 
                        pageCount.current
                    :
                    pageCount.current
            ;
            pagesCollection.current[pageSize][pageIndex].visited = true;
// console.log(`serverData: `, serverData)
// console.log(`
//     pageIndex:      ${pageIndex}
//     pageCount:      ${pageCount.current}
//     hasNextPage:    ${hasNextPage}
//     hasPrevPage:    ${hasPrevPage}
// `)



            // setLoadingTableData(false)
        // }

        setLoadingTableData(false)

    }, [
        fetchIdRef.current,
    ]);






    /**
     * @todo: refactor to refetch data on backend changes!!!
     * @see: https://upmostly.com/tutorials/setinterval-in-react-components-using-hooks
     * This mechanism refetches component thus data fetched from an API every process.env.SOFT4NET_COMPONENT_RERENDER_INTERVAL seconds
     */
    const [componentRerenderCounter, setComponentRerenderCounter] = useState(0);
    useEffect(() => {
        if (`development` === process.env.NODE_ENV) {
console.log(`componentRerenderCounter DISABLED!`)
            return;
        }

        if (!process.env.SOFT4NET_COMPONENT_RERENDER_INTERVAL) {
            return;
        }

        const interval = setInterval(() => {
            invokeFetchData(); // s4n

            const authorization = props.drupalOauthClient.isLoggedIn();
            props.onTodayFetch(authorization);
            props.onYesterdayHasCloseEventFetch(authorization);
            props.onNotificationFetch(authorization);
            props.onGetCashRegisterAmount(authorization);
            props.onProductFetch(authorization);

            setComponentRerenderCounter(componentRerenderCounter => componentRerenderCounter + 1);
        }, process.env.SOFT4NET_COMPONENT_RERENDER_INTERVAL * 1000);

        return () => clearInterval(interval); // the same as componentWillUnmount() ?
    }, 
        [] // the same as componentDidMount()
    );






    const [eventAdd, setEventAdd] = useState(null);
    const formEventAttributesRef = useRef(null);
    /**
     * This action is performed only when form has no errors
     * 
     * @param {*} values 
     * @param {*} actions 
     * @returns 
     */
    const handleSubmitFormAddEvent = async (values, actions) => {
// console.log(`handleSubmitFormAddEvent::values: `, values);
        setLoading(true);
        const allValuesWithEventField = _cloneDeep(values);
        const eventTypeUuid = allValuesWithEventField?.event_type || null;

        const isOrderableEvent = EventEntity.isOrderable(props?.event?.event_types?.data, eventTypeUuid);
        const eventData = EventEntity.prepareData(values, { props });
// console.log(`handleSubmitFormAddEvent::eventData: `, eventData); actions.setSubmitting(false); return;
        let eventFields = {
            ...eventData,
        }

        const authorization = props.drupalOauthClient.isLoggedIn();
        let order_uuid = null;

        try {
            // We can catch request data from dispatched action!
            const { data } = await props.onEventAdd(eventFields, authorization); // This event has no relation to order yet!
// console.log(`data = `, data)
            // When we use await for selector it works, waits till the element will be available from store!!! How is this possible???
            const eventUuid = data.id;
// console.log(`eventUuid = `, eventUuid)

            if (!eventUuid) {
                throw new Error(`eventUuid is NULL!`);
            }

            await EventEntity.createSlaveToMasterEventRelationship(eventUuid, allValuesWithEventField, { authorization, intl });

            if (isOrderableEvent) {
                // Event was created now create an order with context of what event it belongs to
                // Set in order field_event_uuid that contains just created event uuid
                // we need this field also for commerce_stock in case when isShopping event occured so we increase stock not decrease as normally
                order_uuid = await EventRepository.makeOrder({props, intl, eventData, eventUuid});

                if (!order_uuid) {
                    throw new Error('Order was not created!');
                }

                await EventEntity.setFieldOrder(eventUuid, order_uuid, { authorization, intl });
            }

        } catch (error) {
            console.log(error); // console.log("Error", e.stack); console.log("Error", e.name); console.log("Error", e.message);
// alert(error);
        }

        actions.setSubmitting(false);
        actions.resetForm(); // @see: https://formik.org/docs/migrating-v2#resetform

        setEventAdd(false); // dismiss modal
        invokeFetchData(); // s4n
        setLoading(false);
    }

    /**
     * This action is performed only when form has no errors
     * 
     * @param {*} values 
     * @param {*} actions 
     * @returns 
     */
     const handleSubmitFormEditEvent = async (values, actions) => {
// console.log(`handleSubmitFormEditEvent::values: `, values); return;

        setLoading(true);
        const allValuesWithEventField = _cloneDeep(values);
        const eventTypeUuid = allValuesWithEventField?.event_type || null;

        const isOrderableEvent = EventEntity.isOrderable(props?.event?.event_types?.data, eventTypeUuid);
        const eventData = EventEntity.prepareData(values, { props });
// console.log(`handleSubmitFormEditEvent::eventData: `, eventData); actions.setSubmitting(false); return;
        let eventFields = {
            ...eventData,
        }

        const authorization = props.drupalOauthClient.isLoggedIn();
        let order_uuid = null;      

        try {
            // We can catch request data from dispatched action!
            const { data } = await props.onEventPatch(eventFields, authorization);
// console.log(`data = `, data)
            // When we use await for selector it works, waits till the element will be available from store!!! How is this possible???
            const eventUuid = data.id || eventFields.id;
// console.log(`eventUuid = `, eventUuid)

            if (!eventUuid) {
                throw new Error(`eventUuid is NULL!`);
            }

            await EventEntity.createSlaveToMasterEventRelationship(eventUuid, allValuesWithEventField, { authorization, intl });

            if (isOrderableEvent && allValuesWithEventField.replace_old_order_with_new) {
                // Event was created now create an order with context of what event it belongs to
                // Set in order field_event_uuid that contains just created event uuid
                // we need this field also for commerce_stock in case when isShopping event occured so we increase stock not decrease as normally
                order_uuid = await EventRepository.makeOrder({props, intl, eventData, eventUuid});

                if (!order_uuid) {
                    throw new Error('Order was not created!');
                }

                await EventEntity.setFieldOrder(eventUuid, order_uuid, { authorization, intl });
            }
        } catch (error) {
            console.log(error); // console.log("Error", e.stack); console.log("Error", e.name); console.log("Error", e.message);
// alert(error);
        }
        
        actions.setSubmitting(false);
        actions.resetForm(); // @see: https://formik.org/docs/migrating-v2#resetform

        setEventEdit(false); // dismiss modal
        invokeFetchData(); // s4n
        setLoading(false);
    }



    // if (!props?.events?.event_types?.data.length) {
    //     return null;
    // }



    if (null === props.selectTodayEvents()) {
        return null;
    }

    let todayRows_paymentGateway_Transactions = {}
    let todayRowsAllTransactions = 0;
    
    let selectedRows_paymentGateway_Transactions = {}
    let selectedRowsAllTransactions = 0;

    let PAYMENT_METHODS_ALL = [
        ...PAYMENT_METHODS, 
        `pizza_tip`,
        // `negative_trades`, // ujemne transakcje
    ];
    
    PAYMENT_METHODS_ALL.forEach(paymentMethodId => {
        todayRows_paymentGateway_Transactions[paymentMethodId] = 0;
        selectedRows_paymentGateway_Transactions[paymentMethodId] = 0;
    })
    todayRows_paymentGateway_Transactions[`negative_trades`] = 0;
    selectedRows_paymentGateway_Transactions[`negative_trades`] = 0;
    todayRows_paymentGateway_Transactions[`receipt`] = 0;
    selectedRows_paymentGateway_Transactions[`receipt`] = 0;

    if (selectedFlatRows.length) {
        let selectedFlatRowsSubRowsMovedToRoot = [
            ...selectedFlatRows,
            // ...selectedFlatRows.map(flatRow => flatRow?.subRows).map(subRow => subRow), // @todo implement this
        ]
        selectedFlatRows.forEach(flatRow => {
            if (flatRow?.subRows.length) {
                flatRow?.subRows.forEach(subRow => {
                    selectedFlatRowsSubRowsMovedToRoot.push(subRow)
                })
            }
        });
        
        // when we select expanded and unexpanded selectedFlatRows gives us different arrays, 
        // selection all expanded = selectedFlatRows contains subRows at root
        // selection all unexpanded = selectedFlatRows does not contain subRows at root
        selectedFlatRowsSubRowsMovedToRoot = _.uniqBy(selectedFlatRowsSubRowsMovedToRoot, `id`).map(flatRow => flatRow); // removed duplicated flat row objects by it's id value

        {PAYMENT_METHODS_ALL.forEach(paymentMethodId => {
            selectedRows_paymentGateway_Transactions[paymentMethodId] = selectedFlatRowsSubRowsMovedToRoot.reduce((accumulator, item) => {
// console.log(`selectedRows item: `, item)

                if (Number(item.original.raw?.payment_gateway?.[paymentMethodId] || 0) < 0) {
                    selectedRows_paymentGateway_Transactions[`negative_trades`] += Number(item.original.raw?.payment_gateway?.[paymentMethodId] || 0);
                }

                if (item.original.raw?.sales_document?.hasOwnProperty('receipt')) {
                    selectedRows_paymentGateway_Transactions[`receipt`] += Number(item.original.raw?.payment_gateway?.[paymentMethodId] || 0);
                }

                return accumulator + Number(item.original.raw?.payment_gateway?.[paymentMethodId] || 0);
            }, 0);
        })}
// console.log(`selectedFlatRowsSubRowsMovedToRoot: `, selectedFlatRowsSubRowsMovedToRoot)
        selectedRowsAllTransactions = selectedFlatRowsSubRowsMovedToRoot.reduce((accumulator, item) => {
            return accumulator + Number(item.original?.order_total || 0);
        }, 0);
    } else { // if (props.today.data) {
// console.log(`props.today.data: `, props.today)
        props.today.data.forEach(item => { // "node--event"
// console.log(`allRows item: `, item)
// console.log(`EventEntity.getOrderTotalNumberDisplayPurposes: `, EventEntity.getOrderTotalNumberDisplayPurposes(item, { mappedIncludes: getMappedIncludes(props.today.included) }))
            todayRowsAllTransactions += EventEntity.getOrderTotalNumberDisplayPurposes(item, { mappedIncludes: getMappedIncludes(props.today.included) });

            const field_payment_method = EventEntity.getPaymentGateway(item);
            const field_sales_document = EventEntity.getSalesDocument(item);
// console.log(field_payment_method)
            {PAYMENT_METHODS_ALL.forEach(paymentMethodId => {
                todayRows_paymentGateway_Transactions[paymentMethodId] += typeof field_payment_method?.[paymentMethodId] === `undefined` ?  0 : Number(field_payment_method[paymentMethodId]);

                if (typeof field_payment_method?.[paymentMethodId] !== `undefined`) {
                    if (Number(field_payment_method[paymentMethodId]) < 0) {
                        todayRows_paymentGateway_Transactions[`negative_trades`] += Number(field_payment_method[paymentMethodId]);
                    }

                    if (field_sales_document?.hasOwnProperty('receipt')) {
                        todayRows_paymentGateway_Transactions[`receipt`] += Number(field_payment_method[paymentMethodId]);
                    }
                }
            })}
        })
    }

    const MODAL_SHARED_ATTRIBURES = {
        centered: true,
        backdrop: `static`,
    }



    return (
        <React.Fragment>

            {/* <DisplayFormikState {...selectedFlatRows} /> */}
            {/* <DisplayFormikState {...selectedFlatRows.map(d => d.original)} /> */}
            {/* {`fetchIdRef: ${fetchIdRef.current}`} */}

            <div className="row page-titles mx-0">
                <div className="col-sm-6 p-md-0">
                    <div className="welcome-text">
                        {/* <h4>Hi, welcome back!</h4> */}
                        <h4>Lista zdarzeń</h4>
                        {/* <p className="mb-0">Your business dashboard template</p> */}
                    </div>
                </div>
                <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:void(0)">Zdarzenia</a></li>
                        <li className="breadcrumb-item active"><a href="javascript:void(0)">Lista</a></li>
                    </ol>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-2 col-sm-6">
                    <div class="card">
                        <div class="stat-widget-one card-body">
                            <div class="stat-icon d-inline-block">
                                <i class="ti-harddrive text-danger border-danger"></i>
                            </div>
                            <div class="stat-content d-inline-block">
                                <div class="stat-text">Stan kasy (tylko gotówka)</div>
                                <div class="stat-digit">
                                    {EventEntity.formatPrice(`PLN`, props.selectGetCashRegisterAmount())}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {PAYMENT_METHODS_ALL.map(paymentMethodId => {
                    if (`pizza_tip` === paymentMethodId) {
                        return null;
                    }

                    return (
                        <div class="col-lg-2 col-sm-6">
                            <div class="card">
                                <div class="stat-widget-one card-body">
                                    <div class="stat-icon d-inline-block">
                                        {PAYMENT_METHOD_2_ICON[paymentMethodId]}
                                    </div>
                                    <div class="stat-content d-inline-block">
                                        <div class="stat-text">{t({ id: `form_event_attributes_payment_method_${paymentMethodId}`})} ({selectedFlatRows.length ? `zaznaczenie` : `dziś`})</div>
                                        <div class="stat-digit">
                                            {EventEntity.formatPrice(`PLN`, selectedFlatRows.length ? selectedRows_paymentGateway_Transactions[paymentMethodId] : todayRows_paymentGateway_Transactions[paymentMethodId])}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div class="col-lg-2 col-sm-6">
                    <div class="card">
                        <div class="stat-widget-one card-body">
                            <div class="stat-icon d-inline-block">
                                {/* {PAYMENT_METHOD_2_ICON[paymentMethodId]} */}
                                <i class="fas fa-minus-circle"></i>
                            </div>
                            <div class="stat-content d-inline-block">
                                <div class="stat-text">Ujemne transakcje ({selectedFlatRows.length ? `zaznaczenie` : `dziś`})</div>
                                <div class="stat-digit">
                                    {EventEntity.formatPrice(`PLN`, selectedFlatRows.length ? selectedRows_paymentGateway_Transactions[`negative_trades`] : todayRows_paymentGateway_Transactions[`negative_trades`])}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="col-lg-2 col-sm-6">
                    <div class="card">
                        <div class="stat-widget-one card-body">
                            <div class="stat-icon d-inline-block">
                                <i class="ti-link text-danger border-danger"></i>
                            </div>
                            <div class="stat-content d-inline-block">
                                <div class="stat-text">Wszystkie transakcje ({selectedFlatRows.length ? `zaznaczenie` : `dziś`})</div>
                                <div class="stat-digit">
                                    {EventEntity.formatPrice(`PLN`, selectedFlatRows.length ? selectedRowsAllTransactions : todayRowsAllTransactions)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="col-lg-2 col-sm-6">
                    <div class="card">
                        <div class="stat-widget-one card-body">
                            <div class="stat-icon d-inline-block">
                                {/* {PAYMENT_METHOD_2_ICON[paymentMethodId]} */}
                                <i class="fas fa-minus-circle"></i>
                            </div>
                            <div class="stat-content d-inline-block">
                                <div class="stat-text">Paragony transakcje ({selectedFlatRows.length ? `zaznaczenie` : `dziś`})</div>
                                <div class="stat-digit">
                                    {EventEntity.formatPrice(`PLN`, selectedFlatRows.length ? selectedRows_paymentGateway_Transactions[`receipt`] : todayRows_paymentGateway_Transactions[`receipt`])}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            {/* <h4 className="card-title">Zdarzenia</h4> */}
                            <button 
                                styles={{cursor: `pointer`}}
                                onClick={() => {                                
                                    const authorization = props.drupalOauthClient.isLoggedIn();

                                    Promise.allSettled([
                                        // Promise.resolve('a'),
                                        // Promise.reject('b')
                                        props.onTodayFetch(authorization),
                                        props.onYesterdayHasCloseEventFetch(authorization),
                                    ]).then(results => {
                                        // console.log(`done all promisses!`)
                                        setEventAdd(true);
                                    })
                                }} 
                                type="button" className="btn btn-success" disabled={props.selectTodayEventsHasCloseEvent()}>
                                <i className="ti-plus" aria-hidden="true"></i> Dodaj zdarzenie
                            </button>

                        </div>
                        <div className="card-body">

                                <Table
                                    columns={columns}
                                    data={data}
                                    fetchData={fetchData}
                                    loading={loadingTableData}
                                    
                                    // because of component refresh we cannot use state we must keep pageCount value betweeen rerenders of a component!!!
                                    // pageCount={pageCount}
                                    pageCount={pageCount.current}

                                    // pagesCollection={pagesCollection.current} // s4n

                                    // debug={true}
                                    s4nFooter={<CountDown timeleft={process.env.SOFT4NET_COMPONENT_RERENDER_INTERVAL} componentRerenderCounter={componentRerenderCounter} />}
                                    setSelectedFlatRows={setSelectedFlatRows}
                                    setIsAllRowsExpanded={setIsAllRowsExpanded}
                                />

                                <Modal id="event-add" show={eventAdd} onHide={() => setEventAdd(false)} aria-labelledby="contained-modal-title-vcenter" {...MODAL_SHARED_ATTRIBURES}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Dodaj zdarzenie</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <EventAttributes 
                                            context={`event_add`} 
                                            formRef={formEventAttributesRef} 
                                            onSubmit={handleSubmitFormAddEvent}       
                                            setEventActionHandler={setEventAdd}                                             
                                        />
                                    </Modal.Body>
                                </Modal>

                                <Modal id="event-details" show={eventDetails} onHide={() => setEventDetails(false)} aria-labelledby="contained-modal-title-vcenter" {...MODAL_SHARED_ATTRIBURES}>
                                    <EventDetails eventUuid={eventDetails} setEventDetails={setEventDetails} />
                                </Modal>

                                <Modal id="event-edit" show={eventEdit} onHide={() => setEventEdit(false)} aria-labelledby="contained-modal-title-vcenter" {...MODAL_SHARED_ATTRIBURES}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edycja zdarzenia o numerze {eventEdit?.event?.attributes?.drupal_internal__nid}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {eventEdit?.event?.attributes &&
                                            <React.Fragment>
                                                {/* <DisplayFormikState {...eventEdit.event} /> */}
                                                {/* <DisplayFormikState {...eventEdit.order} /> */}
                                                {/* <DisplayFormikState {...eventEdit.included.find(resource => resource.type === `commerce_order--default`)} /> */}

                                                <EventAttributes 
                                                    context={`event_edit`} 
                                                    formRef={formEventAttributesRef} 
                                                    onSubmit={handleSubmitFormEditEvent}       
                                                    setEventActionHandler={setEventEdit}
                                                    orderDetails={(
                                                        <React.Fragment>
                                                            <span>Zamówione produkty</span>
                                                            <EventDetails eventUuid={eventEdit.event.id} showOnlyOrderDetails={true} />
                                                        </React.Fragment>
                                                    )}
                                                    populate={eventEdit.populate}
                                                />
                                            </React.Fragment>
                                        }
                                    </Modal.Body>
                                </Modal>

                                <Modal id="event-remove" show={eventRemove} onHide={() => setEventRemove(false)} aria-labelledby="contained-modal-title-vcenter" {...MODAL_SHARED_ATTRIBURES}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Czy na pewno chcesz usunąć zdarzenie o numerze {eventRemove?.event?.attributes.drupal_internal__nid}?</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Operacja jest nieodwracalna.
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setEventRemove(false)}>Anuluj</Button>
                                        <Button variant="primary" onClick={async () => {
                                            const authorization = props.drupalOauthClient.isLoggedIn();
                                            await props.onEventRemove(eventRemove.event, eventRemove.order, authorization);
                                            setEventRemove(false); // dismiss modal
                                            invokeFetchData(); // s4n
                                        }}>
                                            Usuń
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {
        event: state.shopReducers.event,
        today: state.shopReducers.event.today,
        // user: state.shopReducers.user,
        cart: state.shopReducers.cart,
        checkout: state.shopReducers.checkout,
        selectUserIsAdmin: selectUserIsAdmin(state),
        selectEventTypeByUuid: (uuid) => selectEventTypeByUuid(state, uuid),
        selectTodayEvents: () => selectTodayEvents(state),
        selectTodayEventsHasCloseEvent: () => selectTodayEventsHasCloseEvent(state),
        selectLastOpenDayEvent: () => selectLastOpenDayEvent(state),
        selectYesterdayEventsHasCloseEvent: () => selectYesterdayEventsHasCloseEvent(state),
        selectGetCashRegisterAmount: () => selectGetCashRegisterAmount(state),
    }
};

const mapDispatchToProps = dispatch => ({
    onEventAdd: async (attributes, authorization) => await dispatch(eventAdd(attributes, authorization)),
    // onEventFetch: (authorization) => dispatch(eventFetch(authorization)), // we do not need redux state management
    onEventPatch: (attributes, authorization) => dispatch(eventPatch(attributes, authorization)),
    onEventRemove: (event, order, authorization) => dispatch(eventRemove({event, order, authorization})),
    onTodayFetch: (authorization) => dispatch(todayFetch({authorization})),
    onYesterdayHasCloseEventFetch: (authorization) => dispatch(yesterdayHasCloseEventFetch({authorization})),
    onNotificationFetch: (authorization) => dispatch(notificationFetch({authorization})),
    onGetCashRegisterAmount: (authorization) => dispatch(getCashRegisterAmount({authorization})),
    onProductFetch: (authorization) => dispatch(productFetch({authorization})),

    cartFetch: () => dispatch(cartFetch()),
    getCheckout: (cart) => dispatch(getCheckout(cart)),
    patchCheckout: (cart, attributes) => dispatch(patchCheckout(cart, attributes)), // used in Repository
    postCheckout: (cart, attributes) => dispatch(postCheckout(cart, attributes)), // used in Repository
})

export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthConsumer(Component));