import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../components/layout';

import PrivateRoute from './../../components/s4n/AccountManagement/PrivateRoute';
import Events from './../../components/s4n/AccountManagement/Events'

const Component = () => {
  return (
    <Layout className={`events__page`}>
       
        <PrivateRoute component={Events} />

    </Layout>
  )
}

export default Component;