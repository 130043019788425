/*eslint no-unused-vars: "off"*/
/**
 * @see: https://stackoverflow.com/questions/63287980/updating-initialvalues-prop-on-formik-form-does-not-update-input-value
 * @see: https://codesandbox.io/s/muddy-shape-z1ygv
 */
import React, { useState, useEffect, useMemo } from 'react';
import FormikWithRef from "./../Utility/FormikWithRef";

import { connect } from 'react-redux'
import {
  // eventAdd,
  // eventFetch, // we do not need redux state management
  // eventPatch, 
  // eventRemove,

  todayFetch,
  yesterdayHasCloseEventFetch,

} from './../../s4n/Shop/ext/redux/actions/event'
import { cartAdd, deleteCart } from './../../s4n/Shop/ext/redux/actions/cart'
import { 
  selectEventTypes, 
  selectEventTypeProductVariants, 
  selectTodayEventsHasOpenEvent, 
  selectYesterdayEventsHasCloseEvent,
  selectEventTypeByUuid,
  selectProductVariationByUuid,
  // selectEventsByType,
} from './../../s4n/Shop/ext/redux/selectors/event'

import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
  TextField, Input, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress,
  FormControl, FormHelperText, FormLabel, InputLabel, Select, MenuItem,
  // FormGroup, FormControlLabel, Switch
  InputAdornment,
} from '@material-ui/core';

// @see: https://material-ui.com/components/autocomplete/
// @see: https://next.material-ui.com/components/date-time-picker/
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { 
  Autocomplete, 
  // AdapterDateFns, 
  // LocalizationProvider, 
  // DatePicker,
  TimePicker,
  DateTimePicker,
} from '@material-ui/lab';
import plLocale from 'date-fns/locale/pl';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { useIntl } from "gatsby-plugin-intl"
import Preloader from './../../s4n/Preloader'
import classNames from 'classnames';
import yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form/standalone'
// import { DisplayFormikState } from "./../../formikHelper"
import { colors as ConfigSharedColors } from './../../s4n/Config/Shared'

import { DisplayFormikState } from "./../../forms/formikHelper"
import _, { conforms } from 'lodash'

import { values } from 'lodash';
import Cart from './../../s4n/Shop/ext/pages/Cart';


import { PAYMENT_METHODS, SALES_DOCUMENTS } from './../../s4n/AccountManagement/Events/Repository'; // @todo: implement payment method picker

import moment from 'moment';
import EventEntity, { 
  TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_UUID, 
  TAXONOMY_TERM_EVENT_TYPES_DAY_CLOSE_UUID, 
  TAXONOMY_TERM_EVENT_TYPES_DAY_OPEN_UUID,
  TAXONOMY_TERM_EVENT_TYPES_ATTRACTION_UUID,
  TAXONOMY_TERM_EVENT_TYPES_PACKAGE_UUID,
  TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID,
  TAXONOMY_TERM_EVENT_TYPES_VOUCHER_PURCHASE_UUID,
  TAXONOMY_TERM_EVENT_TYPES_USE_OF_PRODUCTS_UUID,
  TAXONOMY_TERM_EVENT_TYPES_SALE_OF_PRODUCTS,

  TAXONOMY_TERM_FOR_FIELD_RELATED_EVENT_OPTIONS, // Array
} from './../../s4n/AccountManagement/Events/Entity'
import EventRepository, { PAYMENT_METHOD_2_ICON } from './../../s4n/AccountManagement/Events/Repository'
import { Button as BootstrapButton } from 'react-bootstrap'
import { jsonapiClient } from 's4n-jsonapi-client'
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"
import withDrupalOauthConsumer from './../../drupal-oauth/withDrupalOauthConsumer';
// import CommerceHelper from './../../../components/s4n/Shop/s4n/utils/commerce-helper'
import { Event } from 'jquery';

import { exchangePriceToQuantityForCustomPriceProduct } from './../../s4n/Shop/ext/pages/Cart'


// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';



const localeMap = {
  pl: plLocale,
};



// @see: https://stackoverflow.com/questions/49535551/change-secondary-and-primary-colors-in-material-ui
// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    primary: {
      main: ConfigSharedColors.primary
    },
    secondary: {
      main: ConfigSharedColors.secondary
    }
  }
});

const styles = theme => ({
  textFieldContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  textFieldContainerInput: {
    flexGrow: 1,
    marginRight: '5px',
  },
});



const Component = (props) => {
// const Component = React.forwardRef((props, ref) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  const {
    formRef,
    onSubmit,
    // children,
    // initialValues,
    // validationSchema,

    context,
    classes,
    // onChange: parentOnChange,
  } = props;

  const [isOrderableEvent, setIsOrderableEvent] = useState(null)
  const [isAttractionOrPackage, setIsAttractionOrPackage] = useState(null)
  const [isDeposit, setIsDeposit] = useState(null)
  const [isVoucherPurchase, setIsVoucherPurchase] = useState(null)
  const [isReservation, setIsReservation] = useState(null)
  // const [isWorkersCoffee, setIsWorkersCoffee] = useState(null)
  const [isGamingMachines, setIsGamingMachines] = useState(null)
  
  const [isDayOpen, setIsDayOpen] = useState(!props.selectTodayEventsHasOpenEvent())
  const [isDayClose, setIsDayClose] = useState(!props.selectYesterdayEventsHasCloseEvent())
  // const [isDayOpen, setIsDayOpen] = useState(null)
  // const [isDayClose, setIsDayClose] = useState(null)

  const [isUseOfProducts, setIsUseOfProducts] = useState(null)
  const [isDepositReturn, setIsDepositReturn] = useState(null)
  const [isShopping, setIsShopping] = useState(null)
  const [isPizzaOrder, setIsPizzaOrder] = useState(null)
  const [isDecreaseCashRegisterEventType, setIsDecreaseCashRegisterEventType] = useState(null)
  const [canAddBookingInfoStartAndDuration, setCanAddBookingInfoStartAndDuration] = useState(null)
  const [canAddBookingInfoDuration, setCanAddBookingInfoDuration] = useState(null)
  const [shouldDisplayZeroOrderTotalNumber, setShouldDisplayZeroOrderTotalNumber] = useState(null)
  const [isRelationshipSlaveEvent, setIsRelationshipSlaveEvent] = useState(null)

  const [depositAmount, setDepositAmount] = React.useState(0); // opłacona kwota wybranego zadatku z listy
  const [supplementAmount, setSupplementAmount] = React.useState(0); // kwota do dopłaty
  // const [replaceOldOrderWithNew, setReplaceOldOrderWithNew] = React.useState(false); // kwota do dopłaty

  const [selectedProductVariation, setSelectedProductVariation] = React.useState(null); // kwota do dopłaty
  

  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  const [state, setState] = useState({
    loading: false,
    error: false,
    formSchema: null,
    asyncActionLoading: false,
  });
/*
  useEffect(() => {
      const authorization = props.drupalOauthClient.isLoggedIn();

      (async () => {
          // We can catch request data from dispatched action!
          // const { data: todayFetch } = await props.onTodayFetch(authorization);
          // console.log(todayFetch)
          // const { data: yesterdayHasCloseEventFetch } = await props.onYesterdayHasCloseEventFetch(authorization);
          // console.log(yesterdayHasCloseEventFetch)

          Promise.allSettled([
            // Promise.resolve('a'),
            // Promise.reject('b')
            props.onTodayFetch(authorization),
            props.onYesterdayHasCloseEventFetch(authorization),
          ]).then(results => {
            // console.log(`done all promisses!`)
            setIsDayOpen(!props.selectTodayEventsHasOpenEvent())
            setIsDayClose(!props.selectYesterdayEventsHasCloseEvent())
            setState(prevState => ({
              ...prevState,
              loading: false,
            }));
          })
      })();
  }, 
    []
  );
*/
  useEffect(() => {
    const authorization = props.drupalOauthClient.isLoggedIn();
    
    props.onDeleteCart(authorization); // when we close Modal with adding event clear the created cart each time

    return () => { // unmount callback
      // console.log('cleaned up');
      props.onDeleteCart(authorization); // when we close Modal with adding event clear the created cart each time
    };
  }, 
    []
  );



  React.useEffect(() => {
    const cartTotalNumber = props?.cart?.carts?.[0]?.attributes?.order_total?.total.number || 0;
    const supplement = cartTotalNumber - depositAmount;
    setSupplementAmount(supplement);
// console.log(`
//   cartTotalNumber = ${cartTotalNumber}
//   depositAmount = ${depositAmount}
//   supplementAmount = ${supplement}
// `)
  }, [
    depositAmount,
    props.cart,
  ]);

  if (state.loading) {
    return null;
  }



  let initialValues = {

    id: ``, // set when in edit mode
    created: ``,

    /**
     * if yesterday has no close event force creation it
     * if yesterday has close event, then:
     *    if today has open event 
     *        if not force creation it
     *        if yes allow any event to be created (except open login for eventTypeOptions)
     */
    event_type: !isDayClose ? 
      props.selectTodayEventsHasOpenEvent() ? `` : TAXONOMY_TERM_EVENT_TYPES_DAY_OPEN_UUID
        :
      TAXONOMY_TERM_EVENT_TYPES_DAY_CLOSE_UUID,

    event_description: ``, 
    // field_booking_info
      // booking_info_start: new Date(),
      // booking_info_start: `28.03.2021 23:59`,
      booking_info_start: ``,
      booking_info_duration: ``,
    commerce_product_variation: ``,

    payment_method_group: [
      // {'cash_on_delivery': ``},
      // {'credit_card': ``},
      // {'bank_transfer': ``},
    ],
    sales_document_group: [
      // {'receipt': ``},
      // {'invoice': ``},
      // {'invoice_person': ``},
    ],
    // agreement: false,
    // recaptcha: '',
    field_event_init_abstract: [], // stores initial values for field event
    field_event: [], // Składowanie powiązanego zdarzenia typu zadatek

    confirmCounterCash: false, // [x] Potwierdzam zgodność gotówki w kasie
    confirmReceiptsInvoices: false, // [x] Potwierdzam zgodność Paragonów i FVAT

    revision_log: ``,
  }

  let PAYMENT_METHODS_ALL = [
    ...PAYMENT_METHODS, 
    `pizza_tip`
  ];
  // if (isPizzaOrder) {
  //   PAYMENT_METHODS_ALL.push(`tip`);
  // }

  PAYMENT_METHODS_ALL.forEach(payment_method => initialValues.payment_method_group.push({[payment_method]: ``}));
  SALES_DOCUMENTS.forEach(sales_document => initialValues.sales_document_group.push({[sales_document]: ``}));



  // populate fields when in edit mode
  if (props.populate) {
// console.log(`props.populate = `, props.populate)
    for (const [key, value] of Object.entries(props.populate)) {
      if ([
        `payment_method_group`, // Do we need this to be populated? Problem occurs when we populate minus values, quick solution can be found below with using Math.abs() !!!
        `sales_document_group`,
      ].includes(key)) { // @todo: refactor to flatter array so we can easier manipulate/merge elements
        props.populate[key].forEach(oPopulate => { // our values
          const populatKey = Object.keys(oPopulate)[0];
          initialValues[key].forEach(oInitial => { // init values
            const initialKey = Object.keys(oInitial)[0];
            if (populatKey === initialKey) {
              oInitial[initialKey] = Math.abs(oPopulate[populatKey]); // we do not allow minus values here, all values that should be negative will be handled later on in EventEntity::prepareData(), @todo this solution should be moved to EventEntity::formPopulate() !!!
// console.log(`oInitial[initialKey]: `, oInitial[initialKey])
            }
          })
        });
      } else {
        initialValues[key] = value;
      }
    }
  }

  const isEditMode = initialValues.id !== `` ? true : false;
  if (isEditMode) {
    // initialValues.confirmCounterCash = true;
    // initialValues.confirmReceiptsInvoices = true;
    initialValues.revision_log = `Edycja powiązań do zamówienia`;
  }

  const hasItemsInCart = !!props?.cart?.carts?.[0]?.relationships?.order_items?.data?.length || false;
// console.log(`hasItemsInCart = `, hasItemsInCart)  
//   initialValues.replace_old_order_with_new = hasItemsInCart;
  



  /**
   * Custom validator
   * @see: https://github.com/jquense/yup/issues/72
   * 
   * @param {*} obj 
   * @returns 
   */
  const soft4NetCustomInputGroupTest = (obj, path) => { // obj contains all field values

    obj = _.cloneDeep(obj);

// console.log(`${path}: `, path)
    let field_group_at_least_one_provided = 0,
        field_group_all_provided_number = 0,
        field_group_all_provided_number_sum = 0;

    obj[path].forEach(oFieldItem => {
      const fieldKey = Object.keys(oFieldItem)[0];

      // if (`pizza_tip` === fieldKey) {
      //   return;
      // }

// console.log(`fieldKey: `, fieldKey)
      let fieldValue = oFieldItem?.[fieldKey];
// console.log(`fieldValue: `, fieldValue)
// console.log(`typeof fieldValue: `, typeof fieldValue)
      if (typeof fieldValue === `string`) {
        fieldValue = fieldValue.trim();
      }

      if (!_.isEmpty(fieldValue)) {
        field_group_at_least_one_provided++;
// console.log(`_.isNumber(fieldValue): `, Number(fieldValue))
// console.log(`_.isNumber(fieldValue): `, _.isNumber(Number(fieldValue)))
        if (!_.isNaN(Number(fieldValue))) {
          field_group_all_provided_number++;
          field_group_all_provided_number_sum += Number(fieldValue);
        }
      }
    });
// console.log(`
//   field_group_at_least_one_provided: ${field_group_at_least_one_provided}
//   field_group_all_provided_number: ${field_group_all_provided_number}
// `)
    // const field_group_all_provided_number_are = _.isEmpty(field_group_all_provided_number) ? false : Object.keys(field_group_all_provided_number).every(function(k){ return field_group_all_provided_number[k] === true }); // @see: https://stackoverflow.com/questions/17117712/how-to-know-if-all-javascript-object-values-are-true
    if (
      !(
        field_group_at_least_one_provided 
        && field_group_all_provided_number 
        && field_group_at_least_one_provided === field_group_all_provided_number
      )
    ) {
      return new yup.ValidationError(
        `Co najmniej jedno pole numeryczne jest wymagane!`,
        null,
        path, // 'myCustomFieldName'
      );
    }

// console.log(`hasItemsInCart = `, hasItemsInCart)
    if (isUseOfProducts || (isEditMode && !hasItemsInCart)) { // do not check other conditions
      return true;
    }

    if (
      path === `payment_method_group`
      && supplementAmount !== field_group_all_provided_number_sum
    ) {
      return new yup.ValidationError(
        `Suma wprowadzonych kwot jest nieodpowiednia!`,
        null,
        path, // 'myCustomFieldName'
      );
    }

    return true;
}

  let validationSchemaBase = yup.object().shape({
    event_type: yup.string().required(`Proszę wybrać typ zdarzenia!`),
    // event_description: yup.string().required(),
    // ...(isEditMode ? {revision_log: yup.string().required()} : {}),
  })

  let validationSchemaOrderable = yup.object().shape({});
  if (isOrderableEvent) {
      validationSchemaOrderable = validationSchemaOrderable.concat(
          yup.object().shape({
              // payment_method_group: yup.array().required(`Co najmniej jedno pole jest wymagane`),
              // payment_method: yup.string().required(),
              //   payment_method_cash_on_delivery: yup.string()
              //     .oneOf([yup.ref(`payment_method`), null])
              //     .required('required'),
              //   payment_method_credit_card: yup.string()
              //     .oneOf([yup.ref(`payment_method`), null])
              //     .required('required'),
              //   payment_method_bank_transfer: yup.string()
              //     .oneOf([yup.ref(`payment_method`), null])
              //     .required('required'),
              // payment_method_group: yup.array().of(yup.number().min(1)).required(`Co najmniej jedno pole jest wymagane`),
              // payment_method_group: yup.string().oneOf(["green","red","blue"]).required(`Co najmniej jedno pole jest wymagane!`)
              // payment_method_group: yup.array().test(
              //   'is-jimmy',
              //   '${path} is not Jimmy',
              //   async (value, testContext) => (await fetch('/is-jimmy/' + value)).responseText === 'true',
              // }),
              // sales_document_group: yup.array().required(`Co najmniej jedno pole jest wymagane`),
          })
      )

      if (hasItemsInCart) {
        if (!shouldDisplayZeroOrderTotalNumber) {
          validationSchemaOrderable = validationSchemaOrderable.test( // this test is added additional to any other (build-in) tests
            'soft4NetCustomInputGroupTest_payment_method_group',
            null, // we'll return error message ourself if needed
            (obj) => soft4NetCustomInputGroupTest(obj, `payment_method_group`)
          );
        }
  
        if (!shouldDisplayZeroOrderTotalNumber && !isDecreaseCashRegisterEventType) {
          validationSchemaOrderable = validationSchemaOrderable.test( // this test is added additional to any other (build-in) tests
            'soft4NetCustomInputGroupTest_sales_document_group',
            null, // we'll return error message ourself if needed
            (obj) => soft4NetCustomInputGroupTest(obj, `sales_document_group`)
          );
        }

        if (canAddBookingInfoStartAndDuration) {
          validationSchemaOrderable = validationSchemaOrderable.concat(
              yup.object().shape({
                  // commerce_product_variation: yup.string().required(`Proszę dodać produkty do zamówienia!`),
                  // field_booking_info
                  booking_info_start: yup.string().required(),
              })
          )

          if (canAddBookingInfoDuration) {
              validationSchemaOrderable = validationSchemaOrderable.concat(
                  yup.object().shape({
                      // commerce_product_variation: yup.string().required(`Proszę dodać produkty do zamówienia!`),
                      // field_booking_info
                      booking_info_duration: yup.string().required(),
                  })
              )
          }
        }
      }
  }

  if (isDayOpen || isDayClose) {
      validationSchemaOrderable = validationSchemaOrderable.concat(
          yup.object().shape({
            confirmCounterCash: yup.bool().oneOf([true], 'Potwierdzam zgodność gotówki w kasie'),
            confirmReceiptsInvoices: yup.bool().oneOf([true], 'Potwierdzam zgodność Paragonów i FVAT'),
          })
      )
  }
  
  let validationSchema = validationSchemaBase.concat(validationSchemaOrderable);
// console.log(`validationSchema: `, validationSchema)



  // Set data for FormHelper START
  // set form ID!!!
  initialValues.webform_id = `form_event_attributes`;
  // oFormHelper.formEndpoint = '';

  const oFormHelper = new FormHelper(intl);
  
  oFormHelper.t = t;
  oFormHelper.setFormStatus = setFormStatus;
  oFormHelper.state = state;
  oFormHelper.setState = setState;
  // Set data for FormHelper STOP

  // const [eventTypes, setEventTypes] = useState([]);
  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  // useEffect(() => {
  //   // Form schema does not contain markup elements!
  //   oFormHelper.getFormSchema(initialValues.webform_id);
  // }, []);

  const ___onSubmit = async (values, actions) => {
      //setSubmitting(true);
      actions.setStatus(undefined);

      // @todo: this is not working check why!?
      // actions.setStatus({
      //     success: true
      // });
      // actions.resetForm();

      const clonedValues = {...values};

      await setEmailAddress(clonedValues.email);
      emailConfirm && await setEmailConfirmAddress(clonedValues.email_confirm);

      delete clonedValues.webform_id;
      delete clonedValues.email;
      clonedValues.email_confirm && delete clonedValues.email_confirm;
      delete clonedValues.field_telephone;

// console.log(clonedValues)

      // await setAddressCallback(clonedValues);
      await setAddressCallback(prevValues => ({
        ...prevValues,
        ...clonedValues,
      }));

      actions.setSubmitting(false);
  }


  const getFieldEventOption = (item, context) => {
    const { mappedIncludes } = context;

    const field_booking_info = EventEntity.getFieldBookingInfo(item, { intl });
    const relationshipFieldEventType = props.selectEventTypeByUuid(item.relationships.field_event_type.data.id);
    const order_items = EventEntity.getOrderItems(item, { mappedIncludes });
// console.log(order_items)

    // const purchased_entity = EventEntity.getOrderItemsPurchasedEntity(item, { mappedIncludes });

    const variations = order_items?.map((order_item, index) => { // "commerce_product_variation--default"
// console.log(orderItem)
      const purchased_entity = getRelationshipFromMappedIncludes(order_item, 'purchased_entity', mappedIncludes);
      const commerceProductVariationDefault = purchased_entity;

      return `${parseInt(order_item.attributes.quantity)} x ${commerceProductVariationDefault.attributes.title} (${commerceProductVariationDefault.attributes.sku})`;
    })

    const order_total_number = EventEntity.getOrderTotalNumber(item, { mappedIncludes });

    // string.replace(/(\r\n|\n|\r)/gm, "") - remove \n from string ??? IT DOES NOT WORK WHY? instead i use less secure string.trim(), trim() removes white spaces from start and end of string but not from the middle of the string!!!
    const label = `Id: ${item.attributes.drupal_internal__nid} / ${relationshipFieldEventType?.attributes.name} / ${field_booking_info.trim()} / (${variations ? variations.join(`, `) : ``}) = ${EventEntity.formatPrice(`PLN`, order_total_number).trim()}`;

    return {
      code: item.id,
      label,
      order_total_number,
      eventTypeUuid: item.relationships.field_event_type.data.id,
      // disabled: data.length || false, // if exists any event that was found by field_event.id
    }
  }
  // @see: src/components/header.js
  // @todo: shouldn't it be notifications events ???
  const fieldEventOptionsFetch = async (params, values) => {
    const authorization = props.drupalOauthClient.isLoggedIn();

    const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {
        parameters: {
            authorization, 
            includes: null, // inherit incuded from event_fetch main request
            // filter: {
            //   ...params.filter,
            // },
            // sort: [
            //     ...params.sort,
            // ],
            filter: {
                'root': {
                  'group': {
                    'conjunction': 'OR',
                  }
                },
                'filter_object_for__field_booking_info_value': {
                  'condition': {
                    path: 'field_booking_info.value',
                    operator: '>',
                    // value: moment().startOf('day').unix(), // value: moment().unix(), // since now, we do not use = maybe this slows down the query and therefore request ???
                    // value: isEditMode ? moment(values.booking_info_start).startOf('day').unix() : moment().startOf('day').unix(),
                    value: isEditMode ? moment(initialValues.created).startOf('day').unix() : moment().startOf('day').unix(),
                    memberOf: 'root'
                  },
                },
                'filter_object_for__created': {
                  'condition': {
                    path: 'created',
                    operator: '>',
                    // value: moment().startOf('day').unix(), // value: moment().unix(), // since now, we do not use = maybe this slows down the query and therefore request ???
                    // value: isEditMode ? moment(values.booking_info_start).startOf('day').unix() : moment().startOf('day').unix(),
                    value: isEditMode ? moment(initialValues.created).startOf('day').unix() : moment().startOf('day').unix(),
                    memberOf: 'root'
                  },
                },
/*
              ...(isAttractionOrPackage ? 
                  {
                    'root': {
                      'group': {
                        'conjunction': 'OR',
                      }
                    },
                    'filter_object_for__field_booking_info_value': {
                      'condition': {
                        path: 'field_booking_info.value',
                        operator: '>',
                        // value: moment().startOf('day').unix(), // value: moment().unix(), // since now, we do not use = maybe this slows down the query and therefore request ???
                        // value: isEditMode ? moment(values.booking_info_start).startOf('day').unix() : moment().startOf('day').unix(),
                        value: isEditMode ? moment(initialValues.created).startOf('day').unix() : moment().startOf('day').unix(),
                        memberOf: 'root'
                      },
                    },
                    'filter_object_for__created': {
                      'condition': {
                        path: 'created',
                        operator: '>',
                        // value: moment().startOf('day').unix(), // value: moment().unix(), // since now, we do not use = maybe this slows down the query and therefore request ???
                        // value: isEditMode ? moment(values.booking_info_start).startOf('day').unix() : moment().startOf('day').unix(),
                        value: isEditMode ? moment(initialValues.created).startOf('day').unix() : moment().startOf('day').unix(),
                        memberOf: 'root'
                      },
                    },
                  }
                    :
                  {
                    'field_booking_info.value': {
                      operator: '>',
                      // value: moment().startOf('day').unix(), // value: moment().unix(), // since now, we do not use = maybe this slows down the query and therefore request ???
                      // value: isEditMode ? moment(values.booking_info_start).startOf('day').unix() : moment().startOf('day').unix(),
                      value: isEditMode ? moment(initialValues.created).startOf('day').unix() : moment().startOf('day').unix(),
                    },
                  }
                ),
*/
                ...(params?.filter || {}),
            },
            sort: [
                'field_booking_info.value', // field_booking_info.value field in node event entity
                ...(params?.sort || []),
            ]
        },
        options: {
            langcode: intl.locale
        },
    });

    const { data, included, jsonapi, links } = payload;
    const mappedIncludes = getMappedIncludes(included);
    let items = data.map(item => getFieldEventOption(item, { mappedIncludes })) // item === "node--event"

    if (isRelationshipSlaveEvent) {
      return items || []; // [Atrakcja, Pakiet] can have multiple slave events, event the same type already attached, no need for filtering!!!
    }

    // skip already picked/selected events in return
// console.log(values.field_event)
    items = items.filter(option => !values.field_event.includes(option?.code))

    // If there's relation already existing for any other event do not allow to be attached to another event, so search any events by field_event.id
    let filteredItems = [];
    for (let i = 0; i < items.length; i++) {
      const { data: foundRelationUsedForOtherEvents } = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch', {
          parameters: {
              authorization, 
              includes: [], // no includes attached to response
              filter: {
                  'field_event.id': items[i].code, // search by uuid
              },
          },
          options: {
              langcode: intl.locale
          },
      });

      if (isEditMode || !foundRelationUsedForOtherEvents.length) {
        filteredItems.push(items[i]);
      }
    }

    return filteredItems || [];
  }
  // const fieldEventOptionsFetchMemoized = useMemo(async () => await fieldEventOptionsFetch(), [
  // ]);



  // if (!props?.event?.product_fetch?.data.length) {
  //   return null;
  // }



  const componentProps = props;
  const addToCartQuantity = 1;



  return (
    <React.Fragment>
      {oFormHelper.state.loading ? // or state.loading this is a reference
        <Preloader /> : 
        <FormikWithRef
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          // ref={ref} // Formik V1 apporach
          // ref={ref => {
          //   console.log(ref)
          //   // setRef(ref);                                        
          // }}
          onSubmit={onSubmit}
          ref={formRef} // Formik V2 apporach

          // enableReinitialize // Formik does not re-render when initialValues change #811, @see: https://github.com/formium/formik/issues/811
        >
          {(props) => {
            const {
              values,
              errors,
              status,
              touched,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              submitForm,
              setErrors,
              setFieldError,
              setFieldTouched,
            } = props;

            // const handleChange = event => {
            //   props.handleChange(event);
            //   parentOnChange(event, values, componentProps)
            // }

            const formStatusClass = classNames({
              // "mb-0": true,
              "alert": true,
              "d-none": null === formStatus.class,
              [`alert-${formStatus.class}`]: true,
            });

            const InputPropsExtra = {
                disableUnderline: true,

                inputProps: {
                    autocomplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                },
            }

            const TextFieldPropsExtra = {
              InputProps: {
                disableUnderline: true,
                // 'class': 'form-control px-0 mb-4'
              },
              // InputLabelProps: {
              //   shrink: false
              // }

              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }

            /**
             * event: the element that was clicked, an option item (<li>), not main Autocomplete compoinent!!!
             *    Class {dispatchConfig: {…}, _targetInst: FiberNode, _dispatchInstances: FiberNode, nativeEvent: PointerEvent, _dispatchListeners: ƒ, …}
             * newValue: 
             *    {code: "c67e9142-02eb-47cb-bbed-5c2486224e4f", label: "Zadatki"}
             * 
             * @see: https://next.material-ui.com/components/autocomplete/
             * @see: https://material-ui.com/api/autocomplete/
             * @see: https://stackoverflow.com/questions/39076399/cant-get-the-target-attributes-of-material-ui-select-react-component
             * @see: https://stackoverflow.com/questions/59217658/using-material-uis-autocomplete-component-with-formik
             * @see: https://codesandbox.io/s/silly-albattani-yk9z4
             * 
             * @param newValue null | select option value
             */
            const onChangeAutocomplete = (event, newValue, eventNameBypass) => {
              /*
                const eventNameBypass = event.currentTarget.getAttribute('data-name');
                eventNameBypass and newValue are null when set no value or value is cleared when using approach: event.currentTarget.getAttribute('data-name')
              */
// console.log(`onChangeAutocomplete::eventNameBypass: `, eventNameBypass)
// console.log(`onChangeAutocomplete::newValue: `, newValue)

              if (`event_type` === eventNameBypass) {
                const authorization = componentProps.drupalOauthClient.isLoggedIn();
                componentProps.onDeleteCart(authorization); // when we change event type clear the created cart each time
                // setFieldValue(`commerce_product_variation`, initialValues[`commerce_product_variation`]);

                const eventTypeUuid = newValue?.code || ``;
                setFieldValue(eventNameBypass, eventTypeUuid || initialValues[eventNameBypass]);

                setIsOrderableEvent(EventEntity.isOrderable(componentProps.selectEventTypes(), eventTypeUuid));
                setIsAttractionOrPackage(EventEntity.isAttractionOrPackage(eventTypeUuid));
                setIsDeposit(EventEntity.isDeposit(eventTypeUuid));
                setIsVoucherPurchase(EventEntity.isVoucherPurchase(eventTypeUuid));
                setIsReservation(EventEntity.isReservation(eventTypeUuid));
                // setIsWorkersCoffee(EventEntity.isWorkersCoffee(eventTypeUuid))
                setIsGamingMachines(EventEntity.isGamingMachines(eventTypeUuid))
                setIsDayOpen(EventEntity.isDayOpen(eventTypeUuid))
                setIsDayClose(EventEntity.isDayClose(eventTypeUuid))
                setIsUseOfProducts(EventEntity.isUseOfProducts(eventTypeUuid))
                setIsDepositReturn(EventEntity.isDepositReturn(eventTypeUuid))
                setIsShopping(EventEntity.isShopping(eventTypeUuid))
                setIsPizzaOrder(EventEntity.isPizzaOrder(eventTypeUuid))
                setIsDecreaseCashRegisterEventType(EventEntity.isDecreaseCashRegisterEventType(eventTypeUuid))
                setCanAddBookingInfoStartAndDuration(EventEntity.canAddBookingInfoStartAndDuration(eventTypeUuid))
                setCanAddBookingInfoDuration(EventEntity.canAddBookingInfoDuration(eventTypeUuid))
                setShouldDisplayZeroOrderTotalNumber(EventEntity.shouldDisplayZeroOrderTotalNumber(eventTypeUuid))
                setIsRelationshipSlaveEvent(EventEntity.isRelatedEventAllowed(eventTypeUuid))
                
                

                // if (EventEntity.isDepositReturn(eventTypeUuid)) {
                //     componentProps.onAddToCart({
                //       // type: values.commerce_product_variation.type,
                //       type: `commerce_product_variation--default`,
                //       id: `8774110d-1357-41af-87fe-c2ba6d86cd2e`, // "Dowolna cena" - bez podatku
                //     }, exchangePriceToQuantityForCustomPriceProduct(21.67));
                // }
                
              } else if (`commerce_product_variation` === eventNameBypass) {
                const commerceProductVariationUuid = newValue?.code || ``;
                setFieldValue(eventNameBypass, commerceProductVariationUuid || initialValues[eventNameBypass]);
                setSelectedProductVariation(componentProps.selectProductVariationByUuid(commerceProductVariationUuid));
              } else if (`field_event` === eventNameBypass) {
              // newValue === Array
// console.log(newValue) 
// return;
                const aFieldEventUuid = newValue.map(relatedEventOption => relatedEventOption?.code) || [];
                // const fieldEventUuidUnique = [...new Set(fieldEventUuid)]; // returns unique values without duplicates

                setFieldValue(eventNameBypass, aFieldEventUuid || initialValues[eventNameBypass]); // await to be sure that we process as blocking

                const depositOption = newValue.find(relatedEventOption => EventEntity.isDeposit(relatedEventOption?.eventTypeUuid))
                if (depositOption) {
                  setDepositAmount(depositOption?.order_total_number || 0); // newValue if option removed is === null

                  // deposit return logic
                  if (values?.event_type && EventEntity.isDepositReturn(values?.event_type)) {
                    // Zwrot zadatku nie robię jak stworzenie zamowienia o wartosci zwrotu, stan kasy jest odliczany na podstawie pola Gotówka!
                    const payment_method_group = [...values.payment_method_group];
                    payment_method_group.find(oPaymentMethod => oPaymentMethod[`cash_on_delivery`] !== undefined)[`cash_on_delivery`] = depositOption?.order_total_number; // we change object param value by reference
                    setFieldValue(`payment_method_group`, payment_method_group);
                  }
                }

              }



              // setFieldValue(eventNameBypass, newValue || initialValues[eventNameBypass]);
            }

            const eventTypeOptions = componentProps.selectEventTypes()
              .map(taxonomyTermEventTypes => ({
                code: taxonomyTermEventTypes.id,
                label: taxonomyTermEventTypes.attributes.name,
              }));

            const commerceProductVariationOptions = componentProps.selectEventTypeProductVariants(values.event_type);



            /**
             * Async <Autocomplete> options loading
             * @see: https://next.material-ui.com/components/autocomplete/#load-on-open
             */
            const [openFieldRelatedEventOptions, setOpenFieldRelatedEventOptions] = React.useState(false);
            const [fieldRelatedEventOptions, setFieldRelatedEventOptions] = React.useState([]);
            const loadingFieldRelatedEventOptions = openFieldRelatedEventOptions && fieldRelatedEventOptions.length === 0;
            React.useEffect(() => {
              let active = true;
          
              if (!loadingFieldRelatedEventOptions) {
                return undefined;
              }
          
              (async () => {
                  const items = await fieldEventOptionsFetch({
                        filter: {
                          'field_event_type_id': {
                            path: `field_event_type.id`,
                            operator: 'IN',
                            value: [
                              // ...(isDepositReturn ? [TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_UUID] : TAXONOMY_TERM_FOR_FIELD_RELATED_EVENT_OPTIONS),

                              // conditions edges cannot covering!!!
                              ...(isAttractionOrPackage ? TAXONOMY_TERM_FOR_FIELD_RELATED_EVENT_OPTIONS : []),
                              ...(isDepositReturn ? [TAXONOMY_TERM_EVENT_TYPES_DEPOSIT_UUID] : []),
                              ...(isRelationshipSlaveEvent ? [TAXONOMY_TERM_EVENT_TYPES_ATTRACTION_UUID, TAXONOMY_TERM_EVENT_TYPES_PACKAGE_UUID] : []),
                            ],
                        },
                        // Following DOES NOT WORK!!! START
                        // 'field_event_type.id': [
                        //     TAXONOMY_TERM_EVENT_TYPES_VOUCHER_PURCHASE_UUID,
                        //     TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID,
                        //     TAXONOMY_TERM_EVENT_TYPES_SALE_OF_PRODUCTS,
                        //     TAXONOMY_TERM_EVENT_TYPES_USE_OF_PRODUCTS_UUID,
                        // ],
                        // 'field_event_type.id': {
                        //   1: TAXONOMY_TERM_EVENT_TYPES_VOUCHER_PURCHASE_UUID,
                        //   2: TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID,
                        //   3: TAXONOMY_TERM_EVENT_TYPES_SALE_OF_PRODUCTS,
                        //   4: TAXONOMY_TERM_EVENT_TYPES_USE_OF_PRODUCTS_UUID,
                        // },
                        // 'field_event_type.id': `${TAXONOMY_TERM_EVENT_TYPES_VOUCHER_PURCHASE_UUID},${TAXONOMY_TERM_EVENT_TYPES_PIZZA_ORDER_UUID},${TAXONOMY_TERM_EVENT_TYPES_SALE_OF_PRODUCTS},${TAXONOMY_TERM_EVENT_TYPES_USE_OF_PRODUCTS_UUID}`,
                        // Following DOES NOT WORK!!! STOP
                    },
                  }, values);
                  // const items = fieldEventOptionsFetchMemoized;

                  if (active) {
                    setFieldRelatedEventOptions([...items]);
                  }
              })();
          
              return () => {
                active = false;
              };
            }, [loadingFieldRelatedEventOptions]);
            React.useEffect(() => {
              if (!openFieldRelatedEventOptions) {
                setFieldRelatedEventOptions([]);
              }
            }, [openFieldRelatedEventOptions]);

            const hasAddRelatedEventOption = isAttractionOrPackage || isDepositReturn || isRelationshipSlaveEvent;



            // populate fields when in edit mode
            const [fieldRelatedEventInitValue, setFieldRelatedEventInitValue] = React.useState(null);
            useEffect(() => {
              
              // @see: Propably we can return here undefined but not other value, this will cause an error, something like this: "func.apply is not a function"
              // if (!isEditMode) {
              //   return undefined;
              // }

              (async () => {
                if (!isEditMode) {
                  return false;
                }

                if (initialValues.event_type !== ``) {
                  onChangeAutocomplete(null, { code: initialValues.event_type }, `event_type`) // we need to invoke this method in order to call state setters for 
                }

                const authorization = componentProps.drupalOauthClient.isLoggedIn();

                if (initialValues.field_event.length) {
                  let option = []
                  initialValues.field_event.forEach(async eventId => {
                    const { data: item, included } = await EventEntity.getByUuid(eventId, authorization, intl);
                    option.push(getFieldEventOption(item, { mappedIncludes: getMappedIncludes(included) }))
                  })
                  setFieldRelatedEventInitValue(option)
                }
              })();
            }, [
              // componentProps.populate
              // fieldRelatedEventDepositOptions,
              // fieldRelatedEventOptions,
              // initialValues
            ])



            const [voucherNumber, setVoucherNumber] = React.useState(null);
            React.useEffect(() => {
              (async () => {
                const authorization = componentProps.drupalOauthClient.isLoggedIn();

                const response = await fetch(`${process.env.SOFT4NET_SOURCE_BASE_URL}/${intl.locale}/jsonapi/commerce_store/online`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/vnd.api+json',
                        'Content-Type': 'application/vnd.api+json',
                        'Authorization': `${authorization.token_type} ${authorization.access_token}`,
                    },
                })

                const { data } = await response.json(); 

                setVoucherNumber(data?.[0]?.attributes?.field_voucher_number + 1 || null)
              })();
            }, [isVoucherPurchase]);       



            useEffect(() => {
              setFieldValue(`replace_old_order_with_new`, hasItemsInCart);
            }, [
              hasItemsInCart
            ])



            return (
              <Form 
                  // onSubmit={handleSubmit} // @see: https://formik.org/docs/api/form
                  // onSubmit={(e) => {
                  //   handleSubmit(e);
                  // }}
                  className={initialValues.webform_id}
                  autocomplete="off"
                  floatingLabelText="From"
              >
                  <div className="row flex-column">

                        <div className="col-12">
                            <Autocomplete
                              id={`event_type`}
                              // name="event_type" // This name won't be part of fired event!!!
                              options={eventTypeOptions}
                              getOptionLabel={(option) => option.label}
                              {...(componentProps.selectTodayEventsHasOpenEvent() ? { getOptionDisabled: option => EventEntity.isDayOpen(option.code) } : {} )} // when event "Otwarcie dnia" already exists for today 
                              value={eventTypeOptions.find(option => option.code === values.event_type) || { code: ``, label: initialValues.event_type }}
                              // onChange={handleChange}
                              onChange={(event, newValue) => onChangeAutocomplete(event, newValue, `event_type`)} // newValue === {code: "c67e9142-02eb-47cb-bbed-5c2486224e4f", label: "Zadatki"}
                              onBlur={handleBlur}
                              helperText={(errors.event_type && touched.event_type) && errors.event_type}
                              error={errors.event_type && touched.event_type}
                              renderInput={(params) => {
                                // console.log(params) why params does not contains error param from parent component!!!???
                                return <TextField 
                                  error={errors.event_type && touched.event_type} 
                                  label={t({id: `${initialValues.webform_id}_event_type`})} 
                                  variant="outlined" 
                                  {...params} />
                              }}
                              renderOption={(props, option) => ( // option => { code: taxonomyTermEventTypes.id, label: taxonomyTermEventTypes.attributes.name }
                                <li key={option.code} data-name="event_type" {...props}>
                                  {option.label}
                                </li>
                              )}
                              {...TextFieldPropsExtra}
                              className="mb-2" margin="normal" fullWidth autoHighlight
                              disabled={isDayClose || isDayOpen || isEditMode}
                            />

                            {validationSchema.fields.confirmCounterCash &&
                              <React.Fragment>
                                <div className="form-group _form-check">
                                    <Field id="confirmCounterCash" type="checkbox" name="confirmCounterCash" />
                                    <label htmlFor="confirmCounterCash" className="form-check-label">Potwierdzam zgodność gotówki w kasie</label>
                                    {/* <ErrorMessage name="confirmCounterCash" component="div" className="invalid-feedback" /> */}
                                </div>
                              </React.Fragment>
                            }
                            {validationSchema.fields.confirmReceiptsInvoices &&
                              <React.Fragment>
                                <div className="form-group _form-check">
                                    <Field id="confirmReceiptsInvoices" type="checkbox" name="confirmReceiptsInvoices" />
                                    <label htmlFor="confirmReceiptsInvoices" className="form-check-label">Potwierdzam zgodność Paragonów i FVAT</label>
                                    {/* <ErrorMessage name="confirmReceiptsInvoices" component="div" className="invalid-feedback" /> */}
                                </div>
                              </React.Fragment>
                            }
                        </div>

                        {
                          isOrderableEvent && !isEditMode &&
                          // true &&
                          <React.Fragment>
                              <div className="col-12 mb-2">
                                  
                                  {/* This shows ordered items in edit mode */}
                                  {componentProps.orderDetails}

                                  <div className="row">
                                      <div className="col">
                                      <Autocomplete
                                        id={`commerce_product_variation`}
                                        options={commerceProductVariationOptions}
                                        getOptionLabel={(option) => option.label}
                                        // value={values.commerce_product_variation}
                                        value={commerceProductVariationOptions.find(option => option.code === values.commerce_product_variation) || { code: ``, label: initialValues.commerce_product_variation }}
                                        // inputValue={values.commerce_product_variation === initialValues.commerce_product_variation ? initialValues.commerce_product_variation : commerceProductVariationOptions.find(option => option.code === values.commerce_product_variation).label }
                                        // onChange={handleChange}
                                        onChange={(event, newValue) => onChangeAutocomplete(event, newValue, `commerce_product_variation`)} // newValue === {code: "c67e9142-02eb-47cb-bbed-5c2486224e4f", label: "Zadatki"}
                                        onBlur={handleBlur}
                                        helperText={(errors.commerce_product_variation && touched.commerce_product_variation) && errors.commerce_product_variation}
                                        error={errors.commerce_product_variation && touched.commerce_product_variation}
                                        renderInput={(params) => {
                                          // console.log(params) why params does not contains error param from parent component!!!???
                                          return <TextField 
                                            error={errors.commerce_product_variation && touched.commerce_product_variation} 
                                            label={t({id: `${initialValues.webform_id}_commerce_product_variation`})} 
                                            variant="outlined" 
                                            {...params} />
                                        }}
                                        renderOption={(props, option) => ( // option => { code: taxonomyTermEventTypes.id, label: taxonomyTermEventTypes.attributes.name }
                                          <li key={option.code} data-name="commerce_product_variation" {...props}>
                                            {option.label}
                                          </li>
                                        )}
                                        {...TextFieldPropsExtra}
                                        className="" margin="normal" fullWidth autoHighlight
                                      />
                                      </div>
                                      <div className="col-auto d-flex">
                                          <button 
                                            id={`commerce_product_variation_add_to_cart`}
                                            onClick={async (event) => {
                                              // event.stopPropagation();
                                              event.preventDefault();
// console.log(`add to cart button clicked when cart quantity input updated onKey !!!`)
// console.log(values.commerce_product_variation)
// return true;
                                              if (!values.commerce_product_variation) {
                                                return false;
                                              }

                                              const quantity = selectedProductVariation?.attributes?.field_default_quantity || addToCartQuantity;

                                              setState(prevState => ({...prevState, asyncActionLoading: true}));

                                              await componentProps.onAddToCart({
                                                // type: values.commerce_product_variation.type,
                                                type: `commerce_product_variation--default`,
                                                id: values.commerce_product_variation,
                                              }, quantity)

                                              setState(prevState => ({...prevState, asyncActionLoading: false}));

// Set default duration time START
// console.log(`selectedProductVariation.attributes.field_booking_info_duration = `, selectedProductVariation.attributes.field_booking_info_duration)
                                              if (selectedProductVariation?.attributes?.field_booking_info_duration) {
                                                const todayWithAddedMinutesFromMidnight = moment().startOf('day').add(selectedProductVariation.attributes.field_booking_info_duration, 'minutes').toDate()
                                                setFieldValue(`booking_info_duration`, todayWithAddedMinutesFromMidnight);
                                              }
// Set default duration time STOP



                                              // VERY IMPORTANT otherwise this button click event gets invoked from cart update input | WHY ??? !!!
                                              setFieldValue(`commerce_product_variation`, initialValues.commerce_product_variation); // === setFieldValue(`commerce_product_variation`, ``);
                                            }} 
                                            className="btn btn-success add-to-cart pull-right"
                                          >
                                              {t({id: `${initialValues.webform_id}_commerce_product_variation_add_to_cart`})}
                                          </button>
                                      </div>
                                  </div>
                              </div>

                              {hasItemsInCart &&
                                  <React.Fragment>
                                      <div className="col-12">
                                        <div className={`wrapper-cart ${isUseOfProducts || isShopping ? `mb-3` : ``}`}>
                                            <Cart 
                                              disablePromoCodes={true} 
                                              // hidePrices={isUseOfProducts || isShopping}
                                            />
                                        </div>
                                      </div>

                                      <div className="col-12">
                                        <div className="row mb-3">

                                          <React.Fragment>
                                            {!shouldDisplayZeroOrderTotalNumber &&
                                              <div className="col-12 col-lg-3">
                                                <FormControl 
                                                  // sx={{ m: 3 }} 
                                                  component="fieldset" className="m-0" error={errors.payment_method_group && touched.payment_method_group}>
                                                  <FormLabel component="legend">{t({id: `${initialValues.webform_id}_payment_method_group`})}</FormLabel>
                                                  <div className={classes.textFieldContainer}>
                                                    {PAYMENT_METHODS_ALL.map(payment_method => {

                                                      if (!isPizzaOrder && `pizza_tip` === payment_method) { // do not show tip option if not pizza order
                                                        return null;
                                                      }

                                                      return (
                                                        <TextField
                                                          id={`payment_method_group_${payment_method}`}
                                                          name={`payment_method_group`}
                                                          // name={payment_method} // this set into the root of values the {name: value} item
                                                          value={values.payment_method_group.find(oPaymentMethod => oPaymentMethod?.[payment_method] !== undefined)?.[payment_method]}
                                                          label={t({ id: `${initialValues.webform_id}_payment_method_${payment_method}`})}
                                                          error={errors.payment_method_group && touched.payment_method_group}
                                                          // onChange={handleChange}
                                                          onChange={event => {
                                                            const payment_method_group = [...values.payment_method_group];
                                                            payment_method_group.find(oPaymentMethod => oPaymentMethod[payment_method] !== undefined)[payment_method] = event.target.value; // we change object param value by reference
                                                            setFieldValue(`payment_method_group`, payment_method_group);
                                                          }}
                                                          variant="outlined"
                                                          type="text" // @see: https://next.material-ui.com/components/text-fields/#type-quot-number-quot
                                                          InputProps={{
                                                            inputMode: 'numeric', // @see: https://next.material-ui.com/components/text-fields/#type-quot-number-quot
                                                            // pattern: '[0-9]*', // @see: https://next.material-ui.com/components/text-fields/#type-quot-number-quot
                                                            startAdornment: (
                                                              <InputAdornment position="start">
                                                                {PAYMENT_METHOD_2_ICON[payment_method]}
                                                              </InputAdornment>
                                                            ),
                                                          }}
                                                          className={classes.textFieldContainerInput}
                                                        />
                                                      )
                                                    })}
                                                  </div>
                                                  {errors.payment_method_group && 
                                                    <FormHelperText>{errors.payment_method_group}</FormHelperText>
                                                  }
                                                  {/* {supplementAmount &&  */}
                                                    <FormHelperText>Kwota do {depositAmount ? `dopłaty` : `zapłaty`}: {EventEntity.formatPrice(`PLN`, supplementAmount)}</FormHelperText>
                                                  {/* } */}
                                                </FormControl>
                                              </div>
                                            }

                                            {(!shouldDisplayZeroOrderTotalNumber && !isDecreaseCashRegisterEventType ) &&
                                              <div className="col-12 col-lg-4">
                                                <FormControl 
                                                  // sx={{ m: 3 }} 
                                                  component="fieldset" className="m-0" error={errors.sales_document_group && touched.sales_document_group}>
                                                  <FormLabel component="legend">{t({id: `${initialValues.webform_id}_sales_document_group`})}</FormLabel>
                                                  <div className={classes.textFieldContainer}>
                                                    {SALES_DOCUMENTS.map(sales_document => (
                                                      <TextField
                                                        id={`sales_document_${sales_document}`}
                                                        name={`sales_document_group`}
                                                        // name={payment_method} // this set into the root of values the {name: value} item
                                                        value={values.sales_document_group.find(oSalesDocument => oSalesDocument[sales_document] !== undefined)?.[sales_document]}
                                                        label={t({ id: `${initialValues.webform_id}_sales_document_${sales_document}`})}
                                                        error={errors.sales_document_group && touched.sales_document_group}
                                                        // onChange={handleChange}
                                                        onChange={event => {
                                                          const sales_document_group = [...values.sales_document_group];
                                                          sales_document_group.find(oSalesDocument => oSalesDocument[sales_document] !== undefined)[sales_document] = event.target.value; // we change object param value by reference
                                                          setFieldValue(`sales_document_group`, sales_document_group);
                                                        }}
                                                        variant="outlined"
                                                        type="text" // @see: https://next.material-ui.com/components/text-fields/#type-quot-number-quot
                                                        InputProps={{
                                                          inputMode: 'numeric', // @see: https://next.material-ui.com/components/text-fields/#type-quot-number-quot
                                                          pattern: '[0-9]*', // @see: https://next.material-ui.com/components/text-fields/#type-quot-number-quot
                                                          // startAdornment: (
                                                          //   <InputAdornment position="start">
                                                          //     {PAYMENT_METHOD_2_ICON[payment_method]}
                                                          //   </InputAdornment>
                                                          // ),
                                                        }}
                                                        className={classes.textFieldContainerInput}
                                                      />
                                                    ))}
                                                  </div>
                                                  {errors.sales_document_group && 
                                                    <FormHelperText>{errors.sales_document_group}</FormHelperText>
                                                  }
                                                </FormControl>
                                              </div>
                                            }
                                          </React.Fragment>

                                          {canAddBookingInfoStartAndDuration &&
                                            <div className="col-12 col-lg-5">
                                              <FormControl 
                                                // sx={{ m: 3 }} 
                                                component="fieldset" className="m-0" error={(errors.booking_info_start && touched.booking_info_start) || (errors.booking_info_duration && touched.booking_info_duration)}>
                                                <FormLabel component="legend">
                                                  {/* {t({id: `${initialValues.webform_id}_booking_info`})} */}
                                                  &nbsp;
                                                </FormLabel>
                                                <div className={classes.textFieldContainer}>
                                                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[intl.locale]}>
                                                    {/* {componentProps.isDeposit && */}
                                                      <DateTimePicker
                                                        id="booking_info_start"
                                                        name="booking_info_start"
                                                        renderInput={(props) => <TextField {...props} error={errors.booking_info_start && touched.booking_info_start} className={classes.textFieldContainerInput} />}
                                                        value={values.booking_info_start}
                                                        label={t({id: `${initialValues.webform_id}_booking_info_start`})} 
                                                        // error={errors.booking_info_start && touched.booking_info_start}
                                                        // error={errors.booking_info_start && touched.booking_info_start}
                                                        // onChange={handleChange}
                                                        onChange={newValue => { // newValue is Object of type Class === "Date"
        // console.log(newValue); return;
                                                          setFieldValue(`booking_info_start`, newValue);
                                                        }}
                                                        // className="pr-1"
                                                        // minDateTime={new Date()}
                                                        ampm={false} // display 24 hours picker not splitted by AM/PM
                                                        // inputFormat=""
                                                      />
                                                    {/* } */}

                                                    {canAddBookingInfoDuration &&
                                                      <TimePicker
                                                        id="booking_info_duration"
                                                        name="booking_info_duration"
                                                        renderInput={(props) => <TextField {...props} error={errors.booking_info_duration && touched.booking_info_duration} className={classes.textFieldContainerInput} />}
                                                        value={values.booking_info_duration}
                                                        label={t({id: `${initialValues.webform_id}_booking_info_duration`})} 
                                                        // error={errors.booking_info_duration && touched.booking_info_duration}
                                                        // onChange={handleChange}
                                                        onChange={newValue => { // newValue is Object of type Class === "Date"
        // console.log(newValue); return;
                                                          setFieldValue(`booking_info_duration`, newValue);
                                                        }}
                                                        ampm={false} // display 24 hours picker not splitted by AM/PM
                                                        openTo="hours"
                                                        views={['hours', 'minutes']}
                                                        inputFormat="HH:mm"
                                                        mask="__:__"
                                                        // label="With seconds"
                                                      />
                                                    }

                                                  </LocalizationProvider>
                                                </div>
                                              </FormControl>
                                            </div>
                                          }

                                          <React.Fragment>
                                            {isVoucherPurchase && null !== voucherNumber &&
                                              <div className="col-12 col-lg-4">
                                                <FormControl 
                                                  // sx={{ m: 3 }} 
                                                  component="fieldset" className="m-0">
                                                  <FormLabel component="legend">{t({id: `${initialValues.webform_id}_voucher_number_abstract`})}</FormLabel>
                                                  <div className={classes.textFieldContainer}>
                                                    <TextField
                                                      id={`voucher_number_abstract`}
                                                      name={`voucher_number_abstract`}
                                                      // name={payment_method} // this set into the root of values the {name: value} item
                                                      value={voucherNumber}
                                                      // label={t({ id: `${initialValues.webform_id}_voucher_number_abstract`})}
                                                      onChange={handleChange}
                                                      variant="outlined"
                                                      type="text" // @see: https://next.material-ui.com/components/text-fields/#type-quot-number-quot
                                                      InputProps={{
                                                        inputMode: 'numeric', // @see: https://next.material-ui.com/components/text-fields/#type-quot-number-quot
                                                        pattern: '[0-9]*', // @see: https://next.material-ui.com/components/text-fields/#type-quot-number-quot
                                                        // startAdornment: (
                                                        //   <InputAdornment position="start">
                                                        //     {PAYMENT_METHOD_2_ICON[payment_method]}
                                                        //   </InputAdornment>
                                                        // ),
                                                      }}
                                                      disabled
                                                      className={classes.textFieldContainerInput}
                                                    />
                                                  </div>
                                                  <FormHelperText>{``}</FormHelperText>
                                                </FormControl>
                                              </div>
                                            }
                                          </React.Fragment>

                                        </div>
                                      </div>
                                  </React.Fragment>
                              }

                          </React.Fragment>
                        }

                        {hasAddRelatedEventOption &&
                            <div className="col-12">
                                <Autocomplete

                                  multiple
                                  filterSelectedOptions

                                  open={openFieldRelatedEventOptions}
                                  onOpen={() => {
                                    // setFieldRelatedEventInitValue(null); 
                                    setOpenFieldRelatedEventOptions(true)
                                  }}
                                  onClose={() => setOpenFieldRelatedEventOptions(false)}
                                  loading={loadingFieldRelatedEventOptions}

                                  id={`field_event`}
                                  options={fieldRelatedEventOptions}
                                  getOptionLabel={(option) => option.label}
                                  // value={values.commerce_product_variation}
                                  // value={fieldRelatedEventInitValue}

                                  {...(fieldRelatedEventInitValue ? {defaultValue: fieldRelatedEventInitValue} : {defaultValue: fieldRelatedEventOptions.filter(option => values.field_event.includes(option.code))})}
                                  // {...( values.field_event.length ? {defaultValue: fieldRelatedEventOptions.filter(option => values.field_event.includes(option.code))} : {defaultValue: fieldRelatedEventInitValue} )}

                                  // onChange={handleChange}
                                  // inputValue="aaa"
                                  onChange={(event, newValue) => onChangeAutocomplete(event, newValue, `field_event`)} // newValue === {code: "c67e9142-02eb-47cb-bbed-5c2486224e4f", label: "Zadatki"}
                                  onBlur={handleBlur}
                                  helperText={(errors.field_event && touched.field_event) && errors.field_event}
                                  error={errors.field_event && touched.field_event}
                                  renderInput={(params) => {
                                    // console.log(params) why params does not contains error param from parent component!!!???
                                    return <TextField 
                                      error={errors.field_event && touched.field_event} 
                                      label={t({id: `${initialValues.webform_id}_field_event`})} 
                                      variant="outlined" 
                                      {...params} />
                                  }}
                                  renderOption={(props, option) => ( // option => { code: taxonomyTermEventTypes.id, label: taxonomyTermEventTypes.attributes.name }
                                    <li key={option.code} data-name="field_event" {...props}>
                                      {option.label}
                                    </li>
                                  )}
                                  {...TextFieldPropsExtra}
                                  className="mb-2" margin="normal" fullWidth autoHighlight

                                  /**
                                   * (isRelationshipSlaveEvent && !isDeposit) - condition because we need to run discount calculations on actual "Atrakcja", "Pakiet" this is right now impossible to accomplish when assigning "Atrakcja", "Pakiet" to "Zadatek"!
                                   */
                                  disabled={isRelationshipSlaveEvent && isDeposit}

                                />
                            </div>
                        }

                        {!isEditMode &&
                          <div className="col-12">
                            <TextField
                              id={`${initialValues.webform_id}_event_description`}
                              name="event_description"
                              label={t({id: `${initialValues.webform_id}_event_description`})}
                              error={errors.event_description && touched.event_description}
                              // className={classes.textArea}
                              className="mb-2"
                              value={values.event_description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={(errors.event_description && touched.event_description) && errors.event_description}
                              margin="normal"
                              // display as textarea
                              multiline={true}
                              rows="5"
                              rowsmax="5"
                              fullWidth
                              {...TextFieldPropsExtra}
                            />
                          </div>
                        }

                        {/* {isEditMode &&
                          <div className="col-12">
                            <TextField
                              id={`${initialValues.webform_id}_revision_log`}
                              name="revision_log"
                              label={t({id: `${initialValues.webform_id}_revision_log`})}
                              error={errors.revision_log && touched.revision_log}
                              // className={classes.textArea}
                              className="mb-2"
                              value={values.revision_log}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={(errors.revision_log && touched.revision_log) && errors.revision_log}
                              margin="normal"
                              // display as textarea
                              multiline={true}
                              rows="5"
                              rowsmax="5"
                              fullWidth
                              {...TextFieldPropsExtra}
                            />
                          </div>
                        } */}

                        {false && errors &&
                          <div className="col-12">
                            {Object.keys(errors).map(error_field_name => {
                              if (error_field_name === `name`) {
                                return null;
                              }

                              const error_field_message = errors[error_field_name];

                              return (
                                <div key={error_field_name} className="alert alert-danger    mb-1 text-white" role="alert">
                                  {t({id: `${initialValues.webform_id}_${error_field_name}`})}: {error_field_message}
                                </div>
                              )
                            })}
                          </div>
                        }

                        {/* We call submit from parent component */}
                        {true &&
                          <div className="col-12">
                              <div className="row input-send">
                                  <div className="col-12">
                                      {/* <button className="btn btn-primary w-100">Send</button> */}
                                      <DialogActions>
                                          {/* <Button
                                          type="button"
                                          color="secondary"
                                          variant="contained"
                                          className="outline"
                                          onClick={handleReset}
                                          disabled={!dirty || isSubmitting}
                                          >{ t({ id: "soft4net_form_action_reset" }) }</Button> */}
                                          {isSubmitting || state.asyncActionLoading ? 
                                              <CircularProgress /> 
                                                : 
                                              <React.Fragment>
                                                <BootstrapButton variant="secondary" onClick={() => componentProps.setEventActionHandler(false)}>Anuluj</BootstrapButton>
                                                <Button 
                                                    onClick={(e) => {
                                                      if (isOrderableEvent && !hasItemsInCart && !isEditMode) {
                                                        alert(`Proszę dodać produkty do zamówienia!`);
                                                        e.preventDefault();
                                                        return false;
                                                      }

                                                      return true;
                                                    }}
                                                    type="submit" 
                                                    variant="contained" 
                                                    color="primary"
                                                    className={formStatus && formStatus.success ? `btn btn-primary green` : `btn btn-primary`}
                                                    disabled={isSubmitting}
                                                >
                                                  {/* {isSubmitting ? `Dodaję...` : `Dodaj zdarzenie`} */}
                                                  { t({ id: `soft4net_form_action_submit` }) }
                                                </Button>
                                              </React.Fragment>
                                          }
                                      </DialogActions>
                                  </div>
                              </div>
                          </div>
                        }

                  </div>

                  {/* Form status messages */}
                  {true &&
                    <div className="row form-status">
                      <div className="col">
                        <div dangerouslySetInnerHTML={{ __html: formStatus.message }} className={formStatusClass} role="alert"></div>
                      </div>
                    </div>
                  }

                  {/* <DisplayFormikState {...{isOrderableEvent, isAttractionOrPackage, isDeposit, isReservation}} /> */}
                  {/* <DisplayFormikState name="values" {...values} /> */}
                  {/* <DisplayFormikState name="errors" {...errors} /> */}
                  {/* <DisplayFormikState name="touched" {...touched} /> */}

              </Form>
            );
          }}
        </FormikWithRef>
      }
      </React.Fragment>
    )
}
// )

// export default withStyles(styles)(Component)

const mapStateToProps = state => {
  return {
      cart: state.shopReducers.cart,
      event: state.shopReducers.event,

      // selectors
      selectEventTypes: () => selectEventTypes(state),
      selectEventTypeProductVariants: (selectedEventType) => selectEventTypeProductVariants(state, selectedEventType),
      selectTodayEventsHasOpenEvent: () => selectTodayEventsHasOpenEvent(state),
      selectYesterdayEventsHasCloseEvent: () => selectYesterdayEventsHasCloseEvent(state),
      selectEventTypeByUuid: (uuid) => selectEventTypeByUuid(state, uuid),
      // selectEventsByType: (selectedEventType) => selectEventsByType(state, selectedEventType),
      selectProductVariationByUuid: (uuid) => selectProductVariationByUuid(state, uuid),
  }
};

const mapDispatchToProps = dispatch => ({
  // onEventAdd: (authorization) => dispatch(eventAdd(authorization)),
  // onEventPatch: (event, attributes, authorization) => dispatch(eventPatch(event, attributes, authorization)),
  onAddToCart: (activeVariation, quantity) => dispatch(cartAdd(activeVariation, quantity)),
  onDeleteCart: (authorization) => dispatch(deleteCart({authorization})),
  onTodayFetch: (authorization) => dispatch(todayFetch({authorization})),
  onYesterdayHasCloseEventFetch: (authorization) => dispatch(yesterdayHasCloseEventFetch({authorization})),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withDrupalOauthConsumer(Component)));