import React, { useState, useEffect } from 'react'
import withDrupalOauthConsumer from './../../../../drupal-oauth/withDrupalOauthConsumer';
import { jsonapiClient } from 's4n-jsonapi-client' // for events we do not want to user redux, we want to call an API instantly
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"

// s4n
import { useIntl, Link } from 'gatsby-plugin-intl'
import OrderDetails from './../../../AccountManagement/Commerce/Orders/Details';
import { Modal, Button } from 'react-bootstrap'


const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { eventUuid } = props;

    if (!eventUuid) {
        return null;
    }

    const [event, setEvent] = useState(null);

    useEffect(() => {
        componentDidMount();
    }, [
        eventUuid,
    ]);

    const componentDidMount = async () => {       
        const authorization = props.drupalOauthClient.isLoggedIn();

        const payload = await jsonapiClient(process.env.REACT_APP_API_URL, 'event_fetch_one', {
            parameters: {
                authorization, 
                eventUuid,
            },
            options: {
                langcode: intl.locale,
            },
        });

        const { data, included, jsonapi, links } = payload;
        const mappedIncludes = getMappedIncludes(included);
        const relationshipFieldOrder = getRelationshipFromMappedIncludes(data, `field_order`, mappedIncludes); // commerce_order--default

// console.log(relationshipFieldOrder)

        setEvent({
            data: data,
            field_order: relationshipFieldOrder,
            mappedIncludes,
        });
    }

    if (!event) {
        return null;
    }

// console.log(event.data.attributes)

    if (props.showOnlyOrderDetails) {
        return <OrderDetails order={event.field_order} mappedIncludes={event.mappedIncludes} tableCellDisplay={props.tableCellDisplay} />
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Szczegóły zdarzenia o numerze {event.data.attributes.drupal_internal__nid}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <OrderDetails order={event.field_order} mappedIncludes={event.mappedIncludes} tableCellDisplay={props.tableCellDisplay} />
                {event.data?.attributes?.body?.processed && 
                    <React.Fragment>
                        <div><strong>Opis zdarzenia:</strong></div>
                        <div dangerouslySetInnerHTML={{__html: event.data.attributes.body.processed}} />
                    </React.Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.setEventDetails(false)}>Anuluj</Button>
                {/* <Button variant="primary" onClick={async () => {
                    await props.onEventRemove(eventRemove.event, eventRemove.order, props.drupalOauthClient.isLoggedIn());
                    setEventRemove(null); // dismiss modal
                    invokeFetchData(); // s4n
                }}>
                    Usuń
                </Button> */}
            </Modal.Footer>
        </React.Fragment>
    )
};

// export default Component;
export default withDrupalOauthConsumer(Component)