import React from 'react'
import { useAsyncDebounce } from 'react-table'

// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter'



import { useSelector } from 'react-redux'
import moment from 'moment'
import { TextField, Box } from '@material-ui/core';
// @see: https://material-ui.com/components/autocomplete/
// @see: https://next.material-ui.com/components/date-time-picker/
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { FormControl, InputLabel, Select, MenuItem, Slider } from '@material-ui/core';
import { 
  // AdapterDateFns, 
  // LocalizationProvider, 
  DatePicker,
  DateRangePicker,
    // MobileDatePicker as DatePicker,
} from '@material-ui/lab';
import plLocale from 'date-fns/locale/pl';
const localeMap = {
  pl: plLocale,
};

import { selectCommerceProductDefaultProductVariants } from './../../../../Shop/ext/redux/selectors/event'
import { useIntl } from "gatsby-plugin-intl"
import { PAYMENT_METHODS, SALES_DOCUMENTS } from './../../Repository'; // @todo: implement payment method picker


// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)
  
    return (
      <div className="d-flex justify-content-start align-items-center">
        {/* Search:{' '} */}
        <span className="pr-2">Szukaj globalnie:{` `}</span>
        <input
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          // placeholder={`${count} records...`}
          placeholder={`Szukaj po "Id", "Nazwa użytkownika" w ${count} rekordów...`}
          style={{
            flex: `1 0 auto`,
            // width: `100%`,
            fontSize: '1rem',
            border: '0',
          }}
        />
      </div>
    )
  }
  
  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length

    const [state, setState] = React.useState(``)
  
    return (
      <React.Fragment>
        <TextField
          value={state}
          // placeholder={`Search ${count} records...`}
          onChange={e => {
            setState(e.target.value || undefined) // Set undefined to remove the filter entirely
          }}
          onKeyDown={e => {
            if(e.keyCode == 13) {
// console.log('value', e.target.value);

              let filterValue = null;

              if (state) { // this clears the filter from an filter array if has empty value
                filterValue = {
                  operator: `CONTAINS`,
                  value: state,
                }
              }

              setFilter(filterValue || undefined) // Set undefined to remove the filter entirely
            }
         }}
        />
      </React.Fragment>
    )
  }
  
  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const intl = useIntl();
    const t = intl.formatMessage;


// s4n start
    const isColumnFieldEventType = `field_event_type.id` === id;
    let event_types_data = [];
    if (isColumnFieldEventType) {
      event_types_data = useSelector(state => state.shopReducers?.event?.event_types?.data);
    }

    const isColumnOrderItemsProductId = `field_order.order_items.purchased_entity.id` === id;
    let product_fetch_data = [];
    if (isColumnOrderItemsProductId) {
      // product_fetch_data = useSelector(state => state.shopReducers?.event?.product_fetch?.data);
      product_fetch_data = useSelector(state => selectCommerceProductDefaultProductVariants(state));
    }

    const isColumnFieldPaymentMethod = `field_payment_method` === id;
    let field_payment_method = [];
    if (isColumnFieldPaymentMethod) {
      field_payment_method = [
        ...PAYMENT_METHODS, 
        `pizza_tip`
      ];
    }

    const isColumnFieldSalesDocument = `field_sales_document` === id;
    let field_sales_document = [];
    if (isColumnFieldSalesDocument) {
      field_sales_document = [
        ...SALES_DOCUMENTS, 
      ];
    }
    
// s4n stop



    // Calculate the options for filtering
    // using the preFilteredRows
    let options = React.useMemo(() => { // s4n
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])
  
    if (isColumnFieldEventType) {
      options = event_types_data.map(taxonomyTermEventType => taxonomyTermEventType.id)
    }
    if (isColumnOrderItemsProductId) {
      options = product_fetch_data.map(pseudoVariant => pseudoVariant.code)
    }
    if (isColumnFieldPaymentMethod) {
      options = field_payment_method.map(v => v)
    }
    if (isColumnFieldSalesDocument) {
      options = field_sales_document.map(v => v)
    }

    return (
      <FormControl 
        variant="outlined" 
        // className={classes.formControl}
      >
        {/* <InputLabel id="demo-simple-select-outlined-label">Wszystkie</InputLabel> */}
        <Select
          // labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={filterValue}
          onChange={e => {
            let filterValue = e.target.value;

            if (isColumnFieldPaymentMethod || isColumnFieldSalesDocument) {
              if (e.target.value) {
                filterValue = {
                  operator: `CONTAINS`,
                  value: `${e.target.value}:`, // We add ":" because Drupal field contains YAML in searched field and this makes results more "precise", so we search for "invoice_person:" rather than "invoice_person"
                }
              } else {
                filterValue = undefined
              }
            }
            // if (`field_order.order_total.adjustments.0.percentage` === id) {
            //   if (e.target.value) {
            //     filterValue = {
            //       path: `field_order.order_total.adjustments.0.percentage`,
            //       operator: `=`,
            //       value: e.target.value,
            //     }
            //   } else {
            //     filterValue = undefined
            //   }
            // }

            setFilter(filterValue || undefined)
          }}
          // label="Wszystkie"
        >
          <MenuItem value=""><em>Wszystkie</em></MenuItem>
          {options.map((option, i) => {
            let value = option;
            
            let relationshipFieldEventType = null;
            if (isColumnFieldEventType) {
              const taxonomyTermEventTypeId = option;
              relationshipFieldEventType = event_types_data.find(taxonomyTermEventType => taxonomyTermEventType.id === taxonomyTermEventTypeId);
              value = relationshipFieldEventType?.id;
              option = relationshipFieldEventType?.attributes.name;
            }

            let relationshipOrderItemsProductId = null;
            if (isColumnOrderItemsProductId) {
              const commerceProductDefaultId = option;
              relationshipOrderItemsProductId = product_fetch_data.find(pseudoVariant => pseudoVariant.code === commerceProductDefaultId);
              value = relationshipOrderItemsProductId?.code;
              option = relationshipOrderItemsProductId?.label;
            }

            if (isColumnFieldPaymentMethod) {
              value = option;
              option = t({ id: `form_event_attributes_payment_method_${option}`});
            }

            if (isColumnFieldSalesDocument) {
              value = option;
              option = t({ id: `form_event_attributes_sales_document_${option}`});
            }

            return <MenuItem key={i} value={value}>{option}</MenuItem>
          })}
        </Select>
      </FormControl>
    )

    // Render a multi-select box
/*
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => {



// s4n start
          let value = option;
          let relationshipFieldEventType = null;
          if (isColumnFieldEventType) {
            const taxonomyTermEventTypeId = option;
            relationshipFieldEventType = event_types_data.find(taxonomyTermEventType => taxonomyTermEventType.id === taxonomyTermEventTypeId);
            value = relationshipFieldEventType?.id;
            option = relationshipFieldEventType?.attributes.name;
          }
// s4n stop



          return (
            <option key={i} value={value}>
              {option}
            </option>
          )
        })}
      </select>
    )
*/
  }
  
  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the min and max
    // using the preFilteredRows
  
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
      return [min, max]
    }, [id, preFilteredRows])
  
    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={e => {
            setFilter(parseInt(e.target.value, 10))
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    )
  }
  
  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
      return [min, max]
    }, [id, preFilteredRows])
  
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    )
  }



  // Define a default UI for filtering
  function DateColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
// console.log(`filterValue: `, filterValue);
    const timestamp = filterValue?.value || moment().startOf('day').unix();
    const state = moment.unix(timestamp).toDate();
// console.log(`timestamp: `, timestamp);
    const count = preFilteredRows?.length

    // const [state, setState] = React.useState(new Date())
    // const [state, setState] = React.useState(null)
    // const [state, setState] = React.useState(moment.unix(timestamp).toDate())

    return (
      <div className="DatePicker">
        {/* {state.constructor.name} */}
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[`pl`]}>
          <DatePicker
            id="DatePicker1"
            name="DatePicker1"
            renderInput={(props) => <TextField {...props} error={false} />}
            value={state}
            // helperText={``}
            // label="Fitrowanie od daty"
            onChange={newValue => { // newValue is Object of type Class === "Date"
// console.log(newValue);
              if (null === newValue) {
                newValue = moment().startOf('day').toDate();
              }
// console.log(newValue);
// console.log(newValue.constructor.name);
// return;
              // setState(newValue);

              // In Drupal we have created field as: "2021-04-02T19:17:58+00:00",
              let date = {
                operator: `>=`,
                value: moment(newValue.setHours(0, 0, 0, 0)).unix(),
                // value: moment(newValue).format(moment.HTML5_FMT.DATE),
                // value: moment(moment(newValue).format(moment.HTML5_FMT.DATE)).unix(),
              }

              setFilter(date || undefined);
            }}
            // mask=""
            // className="DatePicker"
            // minDateTime={moment().startOf('day')}
            // minDateTime={new Date()}
            // ampm={false} // display 24 hours picker not splitted by AM/PM
          />
        </LocalizationProvider>
      </div>
    )
  }



  // Define a default UI for filtering
  function DateRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id: filterName },
  }) {
// console.log(`filterValue: `, filterValue);
// console.log(`filterName: `, filterName);
    const timestampStart = filterValue?.[0]?.value || moment().startOf('day').unix();
    const timestampEnd = filterValue?.[1]?.value || moment().endOf('day').unix();
    const state = [moment.unix(timestampStart).toDate(), moment.unix(timestampEnd).toDate()];
    const count = preFilteredRows?.length

    return (
      <div className="DatePicker">
        {/* {state.constructor.name} */}
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[`pl`]}>
            <DateRangePicker
                startText="Od"
                endText="Do"
                value={state}
                onChange={newValue => { // newValue is Object of type Class === "Date"
// console.log(newValue); return;

                  // if (null === newValue) {
                  //   newValue = [moment().startOf('day').toDate(), moment().endOf('day').toDate()];
                  // }

                  // In Drupal we have created: "2021-04-02T19:17:58+00:00",
                  // let date = [null, null];
                  let date = [ // default date, this is also set as initial filter in src/components/s4n/AccountManagement/Events/Table/index.js
                    {
                        path: filterName,
                        operator: '>=',
                        value: moment().startOf('day').unix(),
                    },
                    {
                        path: filterName,
                        operator: '<=',
                        value: moment().endOf('day').unix(),
                    },
                  ]

                  if (newValue?.[0] && null === newValue?.[1]) { // only Start date is provided - we allow this
                    date = [
                      {
                        path: filterName,
                        operator: '>=',
                        value: moment(newValue?.[0]).startOf('day').unix(),
                      },
                      null
                    ]
                  }

                  // THOSE QUERIES ARE NOT ALLOWED QUERY TO MANY RESOURCES WILL BE USED!!!
/*
                  if (null === newValue?.[0] && newValue?.[1]) { // only End date is provided
                    date = [
                      null,
                      {
                        path: filterName,
                        operator: '<=',
                        value: moment(newValue?.[1]).endOf('day').unix(),
                      },
                    ]
                  }
                  if (null === newValue?.[0] && null === newValue?.[1]) { // both Start and End date are not provided
                    date = [
                      null,
                      null,
                    ]
                  }
*/

                  if (newValue?.[0] && newValue?.[1]) { // both Start and End date are provided
                    date = [
                      {
                          path: filterName,
                          operator: '>=',
                          value: moment(newValue?.[0]).startOf('day').unix(),
                      },
                      {
                          path: filterName,
                          operator: '<=',
                          value: moment(newValue?.[1]).endOf('day').unix(),
                      },
                    ]
                  }

                  setFilter(date || undefined);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                          <TextField {...startProps} className="mb-2" />
                          {/* <Box sx={{ mx: 2 }}> do </Box> */}
                          <TextField {...endProps} />
                      </div>
                  </React.Fragment>
                )}
                // renderInput={(startProps, endProps) => (
                //   <React.Fragment>
                //     <input ref={startProps.inputRef} {...startProps.inputProps} />
                //     <Box sx={{ mx: 1 }}> to </Box>
                //     <input ref={endProps.inputRef} {...endProps.inputProps} />
                //   </React.Fragment>
                // )}
                
              />
        </LocalizationProvider>
      </div>
    )
  }




  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilterJsonApi({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    // const [min, max] = React.useMemo(() => {
    //   let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    //   let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    //   preFilteredRows.forEach(row => {
    //     min = Math.min(row.values[id], min)
    //     max = Math.max(row.values[id], max)
    //   })
    //   return [min, max]
    // }, [id, preFilteredRows])
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
// s4n start
      min = Math.floor(min);
      max = Math.ceil(max);
// console.log(`React.useMemo():`)
// console.log(`id = `, id)
// console.log(`min = `, min)
// console.log(`max = `, max)
// s4n stop
      return [min, max]
    }, [id, preFilteredRows])

// console.log(`min = `, min)
// console.log(`max = `, max)
// console.log(`stateStart = `, stateStart)
// console.log(`stateEnd = `, stateEnd)
  
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          value={filterValue[0]?.value || Number(min).toString()}
          type="number"
          onChange={e => {
// console.log(e.target.value)
            const val = Number(e.target.value).toString();

            setFilter((old = []) => {
// console.log(old)
                // [val ? parseInt(val, 10) : undefined, old[1]],
                return [
                  {
                      path: id,
                      operator: '>=',
                      value: val,
                  },
                  {
                      path: id,
                      operator: '<=',
                      value: old[1]?.value || Number(max).toString(),
                  },
                ]
            }
            ) // Set undefined to remove the filter entirely
          }}
          placeholder={`Min (${min})`}
          className="mb-2"
        />

        <TextField
          value={filterValue[1]?.value || Number(max).toString()}
          type="number"
          onChange={e => {
// console.log(e.target.value)
            const val = Number(e.target.value).toString();

            setFilter((old = []) => {
// console.log(old)
                // [old[0], val ? parseInt(val, 10) : undefined],
                return [
                  {
                      path: id,
                      operator: '>=',
                      value: old[0]?.value || Number(min).toString(),
                  },
                  {
                      path: id,
                      operator: '<=',
                      value: val,
                  },
                ]
            }
            ) // Set undefined to remove the filter entirely
          }}
          placeholder={`Max (${max})`}
        />
      </div>
    )
  }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function ___NumberRangeColumnFilterJsonApi_0({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    // const [min, max] = React.useMemo(() => {
    //   let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    //   let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    //   preFilteredRows.forEach(row => {
    //     min = Math.min(row.values[id], min)
    //     max = Math.max(row.values[id], max)
    //   })
    //   return [min, max]
    // }, [id, preFilteredRows])
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
// s4n start
      min = Math.floor(min);
      max = Math.ceil(max);
// console.log(`React.useMemo():`)
// console.log(`id = `, id)
// console.log(`min = `, min)
// console.log(`max = `, max)
// s4n stop
      return [min, max]
    }, [id, preFilteredRows])

// console.log(`min = `, min)
// console.log(`max = `, max)
    const [stateStart, setStateStart] = React.useState(Number(min).toString())
    const [stateEnd, setStateEnd] = React.useState(Number(max).toString())
// console.log(`stateStart = `, stateStart)
// console.log(`stateEnd = `, stateEnd)
  
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <TextField
          // value={filterValue[0] || ''}
          value={stateStart}
          type="number"
          onChange={e => {
// console.log(e.target.value)
            // const val = parseInt(e.target.value);
            const val = Number(e.target.value).toString();
            setStateStart(val) // Set undefined to remove the filter entirely
          }}
          onKeyDown={e => {
            if(e.keyCode == 13) {
// console.log('value', e.target.value);

              setFilter((old = []) => 
                  // [val ? parseInt(val, 10) : undefined, old[1]],
                  [
                    {
                        path: id,
                        operator: '>=',
                        value: stateStart,
                    },
                    {
                        path: id,
                        operator: '<=',
                        // value: old[1],
                        value: stateEnd,
                    },
                  ]
              ) // Set undefined to remove the filter entirely

            }
          }}
          placeholder={`Min (${min})`}
        />
        &nbsp;
        <TextField
          // value={filterValue[1] || ''}
          value={stateEnd}
          type="number"
          onChange={e => {
// console.log(e.target.value)
            // const val = parseInt(e.target.value);
            const val = Number(e.target.value).toString();
            setStateEnd(val) // Set undefined to remove the filter entirely
          }}
          onKeyDown={e => {
            if(e.keyCode == 13) {
// console.log('value', e.target.value);

              setFilter((old = []) => 
                  // [old[0], val ? parseInt(val, 10) : undefined],
                  [
                    {
                        path: id,
                        operator: '>=',
                        // value: old[0],
                        value: stateStart,
                    },
                    {
                        path: id,
                        operator: '<=',
                        value: stateEnd,
                    },
                  ]
              ) // Set undefined to remove the filter entirely

            }
          }}
          placeholder={`Max (${max})`}
        />
      </div>
    )
  }

  // Define a default UI for filtering
  function ___NumberRangeColumnFilterJsonApi_1({
    column: { filterValue = [], preFilteredRows, setFilter, id: filterName },
  }) {
// console.log(`filterValue: `, filterValue);
// console.log(`filterName: `, filterName);
    const start = filterValue?.[0]?.value || null;
    const end = filterValue?.[1]?.value || null;
    const state = [start, end];
    const count = preFilteredRows?.length

    return (
      <div className="DatePicker">
        {/* {state.constructor.name} */}
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[`pl`]}>
            <DateRangePicker
                startText="Od"
                endText="Do"
                value={state}
                onChange={newValue => { // newValue is Object of type Class === "Date"
// console.log(newValue); return;
                  let range = [ // default date, this is also set as initial filter in src/components/s4n/AccountManagement/Events/Table/index.js
                    {
                        path: filterName,
                        operator: '>=',
                        value: null,
                    },
                    {
                        path: filterName,
                        operator: '<=',
                        value: null,
                    },
                  ]

                  if (newValue?.[0] && null === newValue?.[1]) { // only Start date is provided - we allow this
                    range = [
                      {
                        path: filterName,
                        operator: '>=',
                        value: newValue?.[0],
                      },
                      null
                    ]
                  }

                  if (newValue?.[0] && newValue?.[1]) { // both Start and End date are provided
                    range = [
                      {
                          path: filterName,
                          operator: '>=',
                          value: newValue?.[0],
                      },
                      {
                          path: filterName,
                          operator: '<=',
                          value: newValue?.[1],
                      },
                    ]
                  }

                  setFilter(range || undefined);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    &nbsp;
                    {/* <Box sx={{ mx: 2 }}> do </Box> */}
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
                
              />
        </LocalizationProvider>
      </div>
    )
  }

  function ___NumberRangeColumnFilterJsonApi_2({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
// s4n start
      min = Math.floor(min);
      max = Math.ceil(max);
// console.log(`React.useMemo():`)
// console.log(`id = ${id}`)
// console.log(`min = ${min}`)
// console.log(`max = ${max}`)
// s4n stop
      return [min, max]
    }, [id, preFilteredRows])

    const [value, setValue] = React.useState([min, max]);

    function valueLabelFormat(value) {
      const units = {
        ['field_house_area']: <>m<sup>2</sup></>,
        ['field_land_area']: <>ar</>,
      };
       
      // return `${value} ${units[id]}`;
      return <>
        {value} 
        {/* {units[id]} */}
      </>;
    }

    const handleChange = (event, newValue) => {
// console.log(newValue) // === [48, 85]
      setValue(newValue);

      // const [min, max] = newValue;
// console.log(`id = ${id}`)
// console.log(`min = ${min}`)
// console.log(`max = ${max}`)

      const range = [
        {
            path: id,
            operator: '>=',
            value: newValue?.[0],
        },
        {
            path: id,
            operator: '<=',
            value: newValue?.[1],
        },
      ]

      setFilter((old = []) => range)
    };

    return <Slider
      value={value}

      min={min}
      step={1}
      max={max}
      // scale={calculateValue}
      getAriaValueText={valueLabelFormat}
      valueLabelFormat={valueLabelFormat}

      // defaultValue={[min, max]}
      onChange={handleChange}
      valueLabelDisplay="auto"
      aria-labelledby="range-slider-demo"
      // getAriaValueText={valuetext}
    />
  }



  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }
  
  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = val => !val



  // Define a custom filter filter function!
  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
      const rowValue = row.values[id]
      return rowValue >= filterValue
    })
  }
  
  // This is an autoRemove method on the filter function that
  // when given the new filter value and returns true, the filter
  // will be automatically removed. Normally this is just an undefined
  // check, but here, we want to remove the filter if it's not a number
  filterGreaterThan.autoRemove = val => typeof val !== 'number'



export { 
    GlobalFilter, DefaultColumnFilter, SelectColumnFilter, SliderColumnFilter, NumberRangeColumnFilter, // Compionents
    fuzzyTextFilterFn, filterGreaterThan, // Functions
    DateColumnFilter, // s4n custom
    DateRangeColumnFilter, // s4n custom
    NumberRangeColumnFilterJsonApi, // s4n custom
}